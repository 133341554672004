import includes from 'lodash/includes';
import map from 'lodash/map';
import castArray from 'lodash/castArray';
import * as actions from './actions';

const INITIAL_STATE = {
  lo: {},
  eventSessions: [],
  maxEnrollCount: Infinity,
  availableEventSessions: [],
  availableEventSessionIds: [],
};

function computeEventSessions(state) {
  const {
    eventSessions,
    availableEventSessionIds,
  } = state;

  let { maxEnrollCount } = state;

  const availableEventSessions = eventSessions.filter((eventSession) => includes(availableEventSessionIds, eventSession.id));

  availableEventSessions.forEach((session) => {
    maxEnrollCount = Math.min(maxEnrollCount, session.availableSeats);
  });

  return {
    ...state,
    availableEventSessions,
    maxEnrollCount,
  };
}

export function contentReducer(state = { ...INITIAL_STATE }, action) {
  switch (action.type) {
    case actions.REQUEST_CONTENT_SUCCEEDED:
      return {
        ...state,
        lo: action.payload || {},
      };

    case actions.REQUEST_CONTENT_EVENTS_SUCCEEDED:
      return computeEventSessions({
        ...state,
        eventSessions: action.payload || [],
      });

    case actions.SET_AVAILABLE_EVENT_SESSIONS:
      return computeEventSessions({
        ...state,
        availableEventSessionIds: map(castArray(action.payload || []), (item) => Number(item))
      });

    case actions.REQUEST_CONTENT_FAILED:
    case actions.REQUEST_CONTENT_EVENTS_FAILED:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default contentReducer;

export const getLO = state => state.content.lo;
export const getEventSessions = state => state.content.eventSessions;
export const getAvailableEventSessions = state => state.content.availableEventSessions;
