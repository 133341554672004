import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Form, FormGroup, Label, FormFeedback, Row, Col } from 'reactstrap';
import Color from 'color';
import { ButtonFilled, foundations } from '@go1d/go1d';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { injectGetMessage } from '../../utils';
import * as actions from '../login/actions';
import { getUser, getAuthenticated } from '../../reducers';
import InlineAlert from '../InlineAlert';
import { signInUser, registerUser, requestEnrolStatus } from '../../api/index';
import { getLO } from '../content/reducers';
import { validation, constraints } from './validate';
import { decodeUserFromJwt } from '../../helpers/userHelper';
import * as paymentDetailActions from '../payment/detail/actions';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    user: getUser(state),
    isAuthenticated: getAuthenticated(state),
    lo: getLO(state),
  };
}

export class Register extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    portalID: PropTypes.number.isRequired,
    lo: PropTypes.objectOf(PropTypes.any).isRequired,
    error: PropTypes.func,
    color: PropTypes.string,
  }

  static defaultProps = {
    color: '#31b8da',
    error: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      first_name: '',
      last_name: '',
      email: '',
      policy: false,
      tos: false,
      collection: false,
      formErrors: { username: '', password: '' },
      error: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  handleChange = (event) => {
    const { target } = event;
    let { value } = target;
    const { name } = target;

    value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({ [name]: value });
    const field = { [name]: target.type === 'checkbox' ? Number(value) : value };
    const err = validation(field, { [name]: constraints[name] });

    if (err) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          ...err,
        },
      });
    } else {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          [name]: '',
        },
      });
    }
  }

  inputValid = name => (this.state.formErrors[name]
    ? 'is-invalid form-control'
    : this.state[name] ? 'is-valid form-control' : 'form-control');

  submitHandler = async (e) => {
    e.preventDefault();
    this.setState({ error: null });
    const {
      email, password, first_name, last_name,
    } = this.state;

    const tos = Number(this.state.tos);
    const policy = Number(this.state.policy);
    const collection = Number(this.state.collection);

    const err = validation({
      email, password, first_name, last_name, tos, policy, collection,
    });

    if (!err) {
      try {
        await registerUser({
          instance: process.env.INSTANCE_URL,
          portal: this.props.portalID,
          email,
          password,
          random: false,
          first_name,
          last_name,
          data: {
            consent: {
              term_policy: policy,
              term_tos: tos,
              term_collection: collection,
              term_privacy: policy,
            },
          },
        });

        const resp = await signInUser({ username: email, password, portal: this.props.portalID });

        requestEnrolStatus(this.props.lo.id, this.props.portalID, resp.user.jwt)
          .then(() => {
            this.props.error(`Error: ${injectGetMessage(getMessage => getMessage({ id: 'error.already_enrolled' }))}`);
          })
          .catch(() => {
            const token = resp.user.jwt;
            const user = decodeUserFromJwt(token);
            this.props.dispatch(actions.loginSucceeded({ authenticated: resp.authenticated, token, user }));
            this.props.dispatch(paymentDetailActions.addSelectedUser(user));
          });
      } catch (error) {
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.status_message || error.response.data.message;
          this.setState({ error: errorMessage });
        } else {
          this.setState({
            error,
          });
        }
      }
    } else {
      this.setState({
        formErrors: {
          ...err,
        },
      });
    }
  }

  render() {
    const primaryColor = this.props.color;
    const hoverColor = Color(primaryColor || foundations.colors.accent)
      .darken(0.2)
      .rgb()
      .string();

    return (
      <Form onSubmit={this.submitHandler} className="login-form">
        <FormGroup tag="fieldset">
          <Row>
            <Col>
              <FormGroup>
                <Label for="email" className="">
                  <FormattedMessage
                    id="app.email"
                    defaultMessage="Email"
                  />
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  className={this.inputValid('email')}
                />
                <FormFeedback>{this.state.formErrors.email}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="first_name" className="">
                  <FormattedMessage
                    id="app.first_name"
                    defaultMessage="First Name"
                  />
                </Label>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={this.state.first_name}
                  onChange={this.handleChange}
                  className={this.inputValid('first_name')}
                />
                <FormFeedback>{this.state.formErrors.first_name}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="last_name" className="">
                  <FormattedMessage
                    id="app.last_name"
                    defaultMessage="Last Name"
                  />
                </Label>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={this.state.last_name}
                  onChange={this.handleChange}
                  className={this.inputValid('last_name')}
                />
                <FormFeedback>{this.state.formErrors.last_name}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="password" className="">
                  <FormattedMessage
                    id="app.password"
                    defaultMessage="Password"
                  />
                </Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  className={this.inputValid('password')}
                />
                <FormFeedback>{this.state.formErrors.password}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="tos"
                  id="tos"
                  value={this.state.tos}
                  onChange={this.handleChange}
                  className={this.inputValid('tos')}
                />
                <Label for="tos" className="">
                  <FormattedHTMLMessage
                    id="app.tos_agree"
                    defaultMessage="I have read and agree to the <a href='https://www.go1.com/tos'>GO1 terms and conditions of service.</a>"
                  />
                </Label>
                <FormFeedback>{this.state.formErrors.tos}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="policy"
                  id="policy"
                  value={this.state.policy}
                  onChange={this.handleChange}
                  className={this.inputValid('policy')}
                />
                <Label for="policy" className="">
                  <FormattedHTMLMessage
                    id="app.tos_age"
                    defaultMessage="I am 16 or more years of age; where I am providing personal information of another person under the age of 16, I am that person’s parent, guardian or legal representative and I have read and agree to the <a href='https://www.go1.com/policy'>GO1 Privacy Policy.</a>"
                  />
                </Label>
                <FormFeedback>{this.state.formErrors.policy}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="collection"
                  id="collection"
                  value={this.state.collection}
                  onChange={this.handleChange}
                  className={this.inputValid('collection')}
                />
                <Label for="collection" className="">
                  <FormattedHTMLMessage
                    id="app.tos_consent"
                    defaultMessage="I consent to GO1 collecting my Name, E-mail address and other information I volunteer, as part of my registration and payment, or consent to be collected or volunteer to GO1 from time to time (including my learning record) to be processed, managed, stored and used as set out in the GO1 Privacy Policy, and to GO1 retaining this information, together with my learning record, until I ask GO1 to delete it."
                  />
                </Label>
                <FormFeedback>{this.state.formErrors.collection}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>
        {this.state.error && <InlineAlert variety="danger" text={this.state.error} />}
        <FormGroup>
          <ButtonFilled
            type="submit"
            size="md"
            color="accent"
            minWidth="200px"
          >
            <FormattedMessage
              id="app.register"
              defaultMessage="Register"
            />
          </ButtonFilled>
        </FormGroup>
      </Form>
    );
  }
}

export default connect(mapStateToProps)(Register);
