import { action } from '../../utils';
import { EventTypes } from 'redux-segment';

export const ENROL = 'PAYMENT/ENROL';
export const ENROL_SUCCEEDED = 'PAYMENT/ENROL/SUCCEEDED';
export const ENROL_FAILED = 'PAYMENT/ENROL/FAILED';

export const enrol = action(ENROL);
export function enrolSucceeded(user, lo, enrolResp) {
  return {
    type: 'PAYMENT/ENROL/SUCCEEDED',
    payload: enrolResp,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Order Completed',
          properties: {
            checkout_id: enrolResp.id,
            user_id: user.id,
            affiliation: lo.portal,
            currency: `${lo.currency}`,
            products: [
              {
                product_id: lo.id,
                sku: lo.id,
                name: lo.title,
                price: `${lo.price}`,
                category: (Array.isArray(lo.tags) ? lo.tags.join(', ') : ''),
              }
            ]
          }
        },
      },
    },
  };
}
export const enrolFailed = action(ENROL_FAILED, true);
