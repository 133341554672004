import axios from 'axios';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

async function eventSession(portalId, loId, coupon, session, courseEnrolmentId, isNotify) {
  const endpoint = 'event/attends';
  const data = {
    taken_portal_id: Number(portalId),
    course_id: loId,
    course_enrolment_id: courseEnrolmentId,
    coupon,
  };

  return axios.post(endpoint, {
    ...data,
    event_id: session.id,
    lo_id: session.eventId,
    parent_lo_id: session.moduleId,
    module_id: session.moduleId,
    is_notify: isNotify,
  });
}

async function eventSessions(portalId, loId, coupon, sessions, courseEnrolmentId, isLastModule) {
  const requests = map(sessions, (session, index) => {
    const isNotify = (index + 1 === Object.keys(sessions).length) && isLastModule;
    eventSession(portalId, loId, coupon, session, courseEnrolmentId, isNotify);
  });

  return axios.all(requests);
}

async function course(tokenId, portalId, lo, coupon) {
  let endpoint = `enrolment/${portalId}/0/${lo.id}/enrolment`;
  if (lo.policyId !== undefined) {
    endpoint = endpoint + `?policyId=${lo.policyId}`;
  }

  const hasPrice = Number(lo.pricing.price);
  const priceOptions =  hasPrice ? {
    coupon,
    paymentMethod: 'stripe',
    paymentOptions: {
      token: tokenId,
    },
  } : null;

  const courseEnrolment = await axios.post(endpoint, priceOptions);

  return Number(courseEnrolment.data.id);
}

async function courseEvents(portalId, loId, coupon, sessions, courseEnrolmentId) {
  const groupEventSessionsByModules = groupBy(sessions, session => session.moduleId);
  const moduleIdArrays = Object.keys(groupEventSessionsByModules);
  const promises = moduleIdArrays.map((moduleId, index) => {
    const items = groupEventSessionsByModules[moduleId];
    const isLastModule = (index + 1 === moduleIdArrays.length);
    if (items.length === 1) {
      return eventSession(portalId, loId, coupon, items[0], courseEnrolmentId, isLastModule);
    }

    return eventSession(portalId, loId, coupon, items[0], courseEnrolmentId, false)
      .then(() => eventSessions(portalId, loId, coupon, items.slice(1), courseEnrolmentId, isLastModule));
  });

  return axios.all(promises);
}

export {
  course,
  courseEvents,
};
