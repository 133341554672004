import React from 'react';
import { View } from '@go1d/go1d';

export const ContentSkeleton = () => (
  <View
    width="100%"
    backgroundColor="background"
    borderRadius={2}
    boxShadow="soft"
    paddingY={3}
    paddingX={3}
  >
    <View
      borderBottom={1}
      borderColor="soft"
      paddingTop={4}
      paddingBottom={5}
      marginX={4}
      flexDirection="row"
    >
      <View
        backgroundColor="muted"
        borderRadius={2}
        height={48}
        width={48}
        marginRight="18px"
      />
      <View
        flexGrow={1}
      >
        <View
          backgroundColor="muted"
          height={21}
          marginBottom={3}
          maxWidth="80%"
        />
        <View
          backgroundColor="muted"
          height={13}
          maxWidth="60%"
        />
      </View>
      <View
        width="15%"
      >
        <View
          backgroundColor="muted"
          height={14}
        />
        <View
          backgroundColor="muted"
          height={12}
          marginTop={2}
          maxWidth="80%"
        />
      </View>
    </View>
    <View
      borderBottom={1}
      borderColor="soft"
      paddingY={4}
      marginX={4}
      flexDirection="column"
    >
      <View
        backgroundColor="muted"
        height={16}
        marginBottom={3}
        maxWidth="80%"
      />
      <View
        backgroundColor="muted"
        height={16}
        marginBottom={5}
        maxWidth="60%"
      />
      <View
        backgroundColor="muted"
        height={16}
        marginBottom={3}
        maxWidth="80%"
      />
    </View>
    <View
      borderBottom={1}
      borderColor="soft"
      paddingY={4}
      marginX={4}
      flexDirection="column"
    >
      <View
        backgroundColor="muted"
        height={18}
        marginBottom={3}
      />
      <View
        backgroundColor="muted"
        height={18}
        marginBottom={3}
      />
      <View
        backgroundColor="muted"
        height={18}
        marginBottom={5}
        maxWidth="60%"
      />
    </View>
    <View
      borderBottom={1}
      borderColor="soft"
      paddingY={4}
      marginX={4}
      flexDirection="row"
      justifyContent="space-between"
    >
      <View
        flexGrow={1}
        maxWidth="20%"
      >
        <View
          backgroundColor="muted"
          height={14}
          marginBottom={2}
        />
        <View
          backgroundColor="muted"
          height={14}
          marginBottom={5}
        />
        <View
          backgroundColor="muted"
          height={21}
          marginBottom={3}
        />
      </View>
      <View
        flexGrow={1}
        maxWidth="30%"
      >
        <View
          backgroundColor="muted"
          height={14}
          marginBottom={2}
        />
        <View
          backgroundColor="muted"
          height={14}
          marginBottom={5}
        />
        <View
          backgroundColor="muted"
          height={21}
          marginBottom={3}
        />
      </View>
    </View>
    <View
      paddingY={4}
      marginX={4}
    >
      <View
        backgroundColor="muted"
        height={18}
        marginBottom={3}
      />
    </View>
  </View>
);

export default ContentSkeleton;
