import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';

import { PaymentContainer, PaymentSubmit } from '../common';
import { purchaseManually } from '../../../api';
import * as actions from '../actions';


class ManualPayment extends React.Component {
  static propTypes = {
    lo: PropTypes.objectOf(PropTypes.any).isRequired,
    payment: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatch: PropTypes.func.isRequired,
  };


  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      hasSuccess: false,
      isWorking: false,
    };
  }


  purchaseManually() {
    const { lo, payment } = this.props;
    const { orderNumber } = this.state;

    return purchaseManually({
      loId: lo.id,
      creditCount: payment.seatCount,
      description: orderNumber,
    });
  }


  handleChange = (event) => {
    const { target } = event;
    this.setState({
      [target.name]: target.value,
    });
  };


  submitForm = async (event) => {
    event.preventDefault();

    try {
      this.props.dispatch(actions.purchaseLearningProcessing());
      this.setState({ isWorking: true });
      await this.purchaseManually();
      this.setState({ hasSuccess: true });
      this.props.dispatch(actions.requestLearningDone());
    } catch (e) {
      this.setState({ hasError: true });
      this.props.dispatch(actions.purchaseLearningError());
    } finally {
      this.setState({ isWorking: false });
    }
  };


  render() {
    const { hasError, hasSuccess, isWorking } = this.state;

    return (
      <Form onSubmit={this.submitForm}>
        <PaymentContainer>
          <FormGroup>
            <Label for="orderNumber">
              <FormattedMessage id="app.payment.orderNumber" defaultMessage="Purchase order number" />
            </Label>
            <Input
              type="textarea"
              id="orderNumber"
              name="orderNumber"
              onChange={this.handleChange}
            />
          </FormGroup>
        </PaymentContainer>

        <PaymentSubmit disabled={isWorking}>
          {!isWorking && (
            <FormattedMessage id="app.payment.sendForApproval" defaultMessage="Send for approval" />
          )}
          {isWorking && (
            <FormattedMessage id="app.processing" defaultMessage="Processing…" />
          )}
        </PaymentSubmit>

        {hasError && (
          <Alert color="danger">
            <FormattedMessage
              id="app.payment.sendForApproval.failure"
              defaultMessage="Failed to send for approval."
            />
          </Alert>
        )}

        {hasSuccess && (
          <Alert color="success">
            <FormattedMessage
              id="app.payment.sendForApproval.success"
              defaultMessage="Your payment has been sent and is waiting for approval."
            />
          </Alert>
        )}
      </Form>
    );
  }
}

export default connect()(ManualPayment);
