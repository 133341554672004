import { decodeUserFromJwt } from '../helpers/userHelper';
import get from "lodash/get";

const localToken = `${window.location.hostname}_go1_pay`;

export function storeLocal(user) {
  try {
    localStorage.setItem(localToken, user);
  } catch (error) {
    /* istanbul ignore next */
    console.log('Unable to persist data to local storage', error);
  }
}

export function retrieveLocal() {
  let user = {};
  try {
    user = decodeUserFromJwt(localStorage.getItem(localToken));
    if (!!user.exp) {
      // add check here to see if malformed JWT stored
      if (user.exp < (new Date().getTime() / 1000) || get(user, 'accounts', []).length <= 0) {
        deleteUserLocal();
        user = false;
      }
    }

  } catch (error) {
    /* istanbul ignore next */
    console.log('No matching user found', error);
    user = false;
  }
  return user;
}

export function deleteUserLocal() {
  try {
    localStorage.removeItem(localToken);
  } catch (error) {
    /* istanbul ignore next */
    console.log('Couldnt delete local user by key.', error);
  }
}
