import { validate } from 'validate.js';
import moment from 'moment';
import { injectGetMessage } from '../../utils';

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse(value) {
    return +moment.utc(value, 'YYYY/MM');
  },
  // Input is a unix timestamp
  format(value, options) {
    const format = options.dateOnly ? 'YYYY/MM' : 'YYYY/MM hh:mm:ss';
    return moment.utc(value, format);
  },
});

export const constraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.email_required' }),
        'email_required',
      ),
    },
    email: {
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.email_valid_required' }),
        'email_valid_required',
      ),
    },
  },
  first_name: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.first_name_required' }),
        'first_name_required',
      ),
    },
  },
  last_name: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.last_name_required' }),
        'last_name_required',
      ),
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.password_required' }),
        'password_required',
      ),
    },
  },
  tos: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.agree_tos' }),
        'agree_tos_1',
      ),
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.agree_tos' }),
        'agree_tos_2',
      ),
    },
  },
  policy: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.agree_pp' }),
        'agree_pp_1',
      ),
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.agree_pp' }),
        'agree_pp_2',
      ),
    },
  },
  collection: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.register_consent' }),
        'register_consent_1',
      ),
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.register_consent' }),
        'register_consent_2',
      ),
    },
  },
};

export function validation(body, cont) {
  return validate(body, cont || constraints);
}
