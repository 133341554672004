import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Color from "color";
import { ButtonFilled, foundations } from '@go1d/go1d';
import { injectGetMessage } from '../../utils';
import * as actions from './actions';
import { getUser, getAuthenticated, getPaymentMethod } from '../../reducers';
import InlineAlert from '../InlineAlert';
import { signInUser, requestEnrolStatus } from '../../api/index';
import { getLO } from '../content/reducers';
import { decodeUserFromJwt } from '../../helpers/userHelper';
import * as paymentDetailActions from '../payment/detail/actions';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    user: getUser(state),
    isAuthenticated: getAuthenticated(state),
    lo: getLO(state),
    paymentType: getPaymentMethod(state),
  };
}

export class LoginForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    portalID: PropTypes.number.isRequired,
    lo: PropTypes.objectOf(PropTypes.any).isRequired,
    error: PropTypes.func.isRequired,
    after: PropTypes.func,
    color: PropTypes.string,
    paymentType: PropTypes.string,
  }

  static defaultProps = {
    color: '#31b8da',
    after: null,
    paymentType: 'creditCard',
  }

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      // form state
      formErrors: { username: '', password: '' },
      formState: { username: null, password: null },
      formValid: false,
      error: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  handleChange = (event) => {
    const { target } = event;
    const { value, name } = target;
    this.setState({ [name]: value });
    const [fieldState, fieldError] = this.validateField(name, value);
    this.setState({
      [name]: value,
      formState: {
        ...this.state.formState,
        [name]: fieldState,
      },
      formErrors: {
        ...this.state.formErrors,
        [name]: fieldError,
      },
    }, () => this.validateForm());
  }

  validateField = (fieldName, value) => {
    let state = 'is-valid';
    let errorMsg = '';
    const emailRegex = new RegExp('[^@]+@[^@]+\\.[^@]+');
    switch (fieldName) {
      case 'username':
        // rule: must be at least 1 char
        if (value.length <= 0) {
          state = 'is-invalid';
          // error for rule
          errorMsg = injectGetMessage(getMessage => getMessage({ id: 'error.username_required' }));
        } else if (!emailRegex.test(value)) {
          state = 'is-invalid';
          // error for rule
          errorMsg = injectGetMessage(getMessage => getMessage({ id: 'error.username_valid_required' }));
        }
        break;
      case 'password':
        // rule: must be at least 1 char
        if (value.length <= 0) {
          state = 'is-invalid';
          // error for rule
          errorMsg = injectGetMessage(getMessage => getMessage({ id: 'error.password_required' }));
        }
        break;
      default:
        break;
    }
    return [state, errorMsg];
  }

  validateForm = () => {
    Object.entries(this.state.formErrors).forEach(([fieldname, error]) => {
      if (error.length > 0) {
        this.setState({ formValid: false });
      } else {
        this.setState({ formValid: true });
      }
    });
  }

  submitHandler = (e) => {
    e.preventDefault();
    // const { portal } = this.props;
    const { username, password } = this.state;
    const isDefaultPayment = this.props.paymentType !== 'ebanx';
    // check for blank
    // better way to do this?
    if (username === '') {
      this.state.formValid = false;
      this.state.formState.username = 'is-invalid';
      this.state.formErrors.username = injectGetMessage(getMessage => getMessage({ id: 'error.username_required' }));
    }
    if (password === '') {
      this.state.formValid = false;
      this.state.formState.password = 'is-invalid';
      this.state.formErrors.password = injectGetMessage(getMessage => getMessage({ id: 'error.password_required' }));
    }
    if (this.state.formValid) {
      signInUser({ username, password, portal: this.props.portalID }).then((resp) => {
        requestEnrolStatus(this.props.lo.id, this.props.portalID, resp.user.jwt)
          .then((status) => {
            if (!isDefaultPayment) {
              this.props.after('alreadyEnrolled', true);
              this.props.error(injectGetMessage(getMessage => getMessage({ id: 'error.already_enrolled' })));
            } else {
              const token = resp.user.jwt;
              const user = decodeUserFromJwt(token);
              this.props.dispatch(actions.loginSucceeded({
                authenticated: resp.authenticated,
                token,
                user,
                enrolled: true
              }));
            }
          })
          .catch((err) => {
            console.log(err);
            const token = resp.user.jwt;
            const user = decodeUserFromJwt(token);
            this.props.dispatch(actions.loginSucceeded({ authenticated: resp.authenticated, token, user }));
            this.props.dispatch(paymentDetailActions.addSelectedUser(user));
          });
      }, () => {
        this.setState({
          error: true,
        });
      });

      // this.props.dispatch(actions.login({ username, password }));
    } else {
      Object.entries(this.state.formErrors).forEach(([fieldname, error]) => {
        const formState = { ...this.state.formState };
        formState[fieldname] = 'is-invalid';
        this.setState({ formState });
      });
    }
  }

  render() {
    const { username, password } = this.state;
    const primaryColor = this.props.color;
    const hoverColor = Color(primaryColor || foundations.colors.accent)
      .darken(0.2)
      .rgb()
      .string();
    const invalidLogin = injectGetMessage(getMessage => getMessage({ id: 'error.login_invalid' }));
    return (
      <Form onSubmit={this.submitHandler} className="login-form">
        <FormGroup>
          <Label for="username_input" className="">
            <FormattedMessage
              id="app.email"
              defaultMessage="Email"
            />
          </Label>
          <Input
            type="text"
            name="username"
            id="username_input"
            placeholder=""
            defaultValue={username}
            onChange={this.handleChange}
            className={`${this.state.formState.username} form-control`}
          />
          <FormFeedback>{this.state.formErrors.username}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="password_input" className="">
            <FormattedMessage
              id="app.password"
              defaultMessage="Password"
            />
          </Label>
          <Input
            type="password"
            name="password"
            id="password_input"
            defaultValue={password}
            onChange={this.handleChange}
            className={`${this.state.formState.password} form-control`}
          />
          <FormFeedback>{this.state.formErrors.password}</FormFeedback>
        </FormGroup>
        {this.state.error && <InlineAlert variety="danger" text="">{invalidLogin}</InlineAlert>}
        <FormGroup>
          <ButtonFilled
            type="submit"
            size="md"
            color="accent"
            minWidth="200px"
          >
            <FormattedMessage
              id="app.signIn"
              defaultMessage="Sign In"
            />
          </ButtonFilled>
        </FormGroup>
      </Form>
    );
  }
}

export default connect(mapStateToProps)(LoginForm);
