import React from 'react';
import PropTypes from 'prop-types';

export const Price = ({ price, currency, loading }) => {
  if (price === null && !loading) {
    return <span className="price">FREE</span>;
  }

  if (price === null && loading) {
    return <span className="price">...</span>;
  }

  if (price === 0) {
    return (
      <span className="price">FREE</span>
    );
  }

  const dollarSign = ['AUD', 'USD'].indexOf(currency) >= 0 ? '$' : '';
  return (
    <span className="price">
      {currency === 'BRL' ? `R$${price.toFixed(2).replace(".", ",")}` : `${currency} ${dollarSign}${price.toFixed(2)}`}
    </span>
  );
};

Price.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
  loading: PropTypes.bool,
};

Price.defaultProps = {
  price: null,
  currency: '',
  loading: false,
};
