import React from 'react';
import scriptLoader from 'react-async-script-loader';
import PropTypes from 'prop-types';
import TextMask from 'react-text-mask';
import range from 'lodash/range';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcAmex, faCcDiscover, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { Alert, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { Select, TextInput } from '@go1d/go1d';

import { purchaseLo, purchaseLi, trackConversion } from 'src/api';
import { Price } from 'src/components/content/common/Price';
import * as actions from 'src/components/PaymentForm/actions';
import { PaymentContainer, PaymentSubmit } from '../common';

function getMonthOptions() {
  return range(1, 13)
    .map(item => ({
      value: item,
      label: String(item < 10 ? `0${item}` : item),
    }));
}

function getYearOptions() {
  return range(2023, 2034)
    .map(item => ({
      value: item,
      label: String(item),
    }));
}

function getCreditMask() {
  const group = [/\d/, /\d/, /\d/, /\d/];
  return [...group, ' ', ...group, ' ', ...group, ' ', ...group];
}

function getCVCMask() {
  return [/\d/, /\d/, /\d/, /\d/];
}

class CreditCard extends React.Component {
  static propTypes = {
    lo: PropTypes.objectOf(PropTypes.any).isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    payment: PropTypes.objectOf(PropTypes.any).isRequired,
    eventSessions: PropTypes.arrayOf(PropTypes.any),
    isAdmin: PropTypes.bool,
    portal: PropTypes.objectOf(PropTypes.any).isRequired,
    appOrigin: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    primaryColor: PropTypes.string,
    loading: PropTypes.bool,
    paymentApi: PropTypes.oneOf(['normal']),
  };

  static defaultProps = {
    eventSessions: [],
    isAdmin: false,
    primaryColor: null,
    loading: false,
    paymentApi: 'normal',
  };

  constructor(props) {
    super(props);

    this.state = {
      errorMsg: null,
      isWorking: false,
    };

    this.monthOptions = getMonthOptions();
    this.yearOptions = getYearOptions();
    this.creditMask = getCreditMask();
    this.cvcMask = getCVCMask();
  }

  getCardInfo() {
    const { state } = this;
    return {
      name: state.cardName,
      number: state.cardNumber,
      exp_month: state.cardMonth,
      exp_year: state.cardYear,
      cvc: state.cardCvc,
    };
  }

  async purchaseLearning() {
    const {
      lo, payment, portal, eventSessions, isAdmin, user, paymentApi
    } = this.props;

    const bypass = payment.totalAmount === 0;
    const cardInfo = bypass ? null : this.getCardInfo();
    const userEmails = payment.selectedUsers.map(item => item.mail);

    const purchaseOptions = {
      portal,
      lo,
      cardInfo,
      creditCount: payment.seatCount,
      userEmails,
      eventSessions,
      isAdmin,
      actor: user,
      paymentApi,
    };

    if (payment.coupon) {
      purchaseOptions.coupon = payment.coupon.code;
    }

    if (lo.type !== 'course') {
      return purchaseLi(purchaseOptions);
    }
    return purchaseLo(purchaseOptions);
  }

  trackConversion(enrolmentId) {
    const {
      appOrigin, lo, payment, user,
    } = this.props;
    const { selectedUsers } = payment;

    const isSelfPurchased = payment.seatCount === 1
      && selectedUsers.length === 1
      && selectedUsers[0].mail === user.mail;
    const conversionType = isSelfPurchased ? 'buy-now' : 'assign';

    trackConversion(lo.id, conversionType, enrolmentId, appOrigin);
  }

  handleChange = (event) => {
    const { target } = event;
    this.setState({
      [target.name]: target.value,
    });
  };

  submitForm = async (event) => {
    event.preventDefault();

    let enrolmentId = null;
    try {
      this.setState({ isWorking: true });
      this.props.dispatch(actions.purchaseLearningProcessing());
      enrolmentId = await this.purchaseLearning();
      this.props.dispatch(actions.purchaseLearningSucceeded());
    } catch (ex) {
      const msg = ex.toString();
      this.props.dispatch(actions.purchaseLearningError());
      this.setState({
        errorMsg: msg,
        isWorking: false
      });
    }

    if (enrolmentId) {
      this.trackConversion(enrolmentId);
    }
  };

  render() {
    const { payment, user, primaryColor } = this.props;
    const { isWorking, errorMsg } = this.state;

    return (
      <Form onSubmit={this.submitForm}>
        {payment.totalAmount !== 0 && (
          <PaymentContainer>
            <FormGroup>
              <Label for="cardName">
                <FormattedMessage id="app.cardName" defaultMessage="Name on card" />
              </Label>
              <TextInput
                id="cardName"
                name="cardName"
                placeholder={user.name}
                required
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup>
              <span className="card-icons">
                <FontAwesomeIcon icon={faCcVisa} />
                <FontAwesomeIcon icon={faCcMastercard} />
                <FontAwesomeIcon icon={faCcAmex} />
                <FontAwesomeIcon icon={faCcDiscover} />
                <span className="cc-icon hipercard" />
                <span className="cc-icon elo" />
                <span className="cc-icon aura" />
              </span>
              <Label for="cardNumber">
                <FormattedMessage id="app.cardNumber" defaultMessage="Card number" />
              </Label>
              <TextMask
                id="cardNumber"
                name="cardNumber"
                required
                mask={this.creditMask}
                placeholder="×××× ×××× ×××× ××××"
                guide={false}
                render={(ref, props) => <TextInput innerRef={ref} {...props} />}
                onChange={this.handleChange}
              />
            </FormGroup>

            <Row>
              <Col xs="12" md="8">
                <FormGroup className="resetSelectStyle">
                  <Label>
                    <FormattedMessage id="app.payment.cardExpiry" defaultMessage="Card expiry" />
                  </Label>
                  <Row>
                    <Col>
                      <Select
                        name="cardMonth"
                        height="38"
                        defaultText="MM"
                        options={this.monthOptions}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <Select
                        name="cardYear"
                        height="38"
                        defaultText="YYYY"
                        options={this.yearOptions}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col xs="12" md="4">
                <FormGroup className="cvv-form-group">
                  <Label for="cardCvc">
                    <FormattedMessage id="app.payment.cardCvc" defaultMessage="CVC" />
                  </Label>
                  <TextMask
                    id="cardCvc"
                    name="cardCvc"
                    placeholder="×××"
                    mask={this.cvcMask}
                    required
                    guide={false}
                    render={(ref, props) => <TextInput iconName="CreditCard" innerRef={ref} {...props} />}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </PaymentContainer>
        )}

        {payment.totalAmount === 0 && (
          <PaymentContainer cssProps={{ backgroundColor: 'background', border: 0, css: { boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', borderRadius: 2 } }}>
            <FormGroup>
              <div className="txt-lg txt-center">
                <FormattedMessage id="app.payment.freeForm.nopayment" defaultMessage="No payment required" />
              </div>
              <div className="txt-sm txt-center txt-grey m-t-sm">
                <FormattedMessage id="app.payment.freeForm.confirm" defaultMessage="Confirm your purchase below" />
              </div>
            </FormGroup>
          </PaymentContainer>
        )}

        <PaymentSubmit disabled={isWorking} primaryColor={primaryColor}>
          {!isWorking && (
            <FormattedMessage
              id="app.payment.purchaseForPrice"
              defaultMessage="Purchase for {price}"
              values={{ price: <Price price={payment.totalAmount} loading currency={payment.currency} /> }}
            />
          )}
          {isWorking && (
            <FormattedMessage id="app.processing" defaultMessage="Processing…" />
          )}
        </PaymentSubmit>

        {errorMsg && (
          <Alert color="danger">
            <FormattedMessage
              id="app.payment.generalFailure"
              defaultMessage={errorMsg}
            />
          </Alert>
        )}
      </Form>
    );
  }
}

export default connect()(scriptLoader('https://js.stripe.com/v2/')(CreditCard));
