import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { Form, Input, FormGroup, Label, Button, Alert } from 'reactstrap';
import { Link } from '@go1d/go1d';
import { requestCouponByCode } from '../../../api';
import { getPaymentDetail } from '../../../reducers';
import { addCoupon, removeCoupon } from '../../payment/detail/actions';
import { validateCoupon } from '../../../helpers/couponHelper';

import './CouponForm.scss';

/* istanbul ignore next */
const mapStateToProps = state => ({
  paymentDetail: getPaymentDetail(state),
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({ dispatch });

export class CouponForm extends React.Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(this.props.location.search);

    this.state = {
      portalId: Number(query.portal) || null,
      loId: Number(query.lo),
      currency: this.props.paymentDetail.currency,
      showCouponForm: false,
      disabled: false,
      validated: false,
      error: null,
      coupon: '',
    };
  }

  setInvalid = (err) => {
    let error = {
      id: 'app.content.coupon.invalid',
      message: 'Invalid coupon',
    };
    if (err.message === 'Forbidden') {
      error = {
        id: 'app.content.coupon.forbidden',
        message: 'Please register or sign in to validate coupon.'
      };
    }
    this.setState({
      disabled: false,
      error,
    });
  }

  submitHandler = async (event) => {
    event.preventDefault();
    const {
      portalId, coupon, loId, currency
    } = this.state;

    this.setState({
      disabled: true,
    });

    try {
      const response = await requestCouponByCode(portalId, coupon);
      if (response.response && response.response.status === 403) {
        throw new Error('Forbidden');
      }
      const validatedCoupon = await validateCoupon(response, loId, currency);
      this.validate(validatedCoupon);
    } catch (err) {
      console.log(err);
      this.setInvalid(err);
    }
  }

  validate = (coupon) => {
    if (!coupon.valid) {
      this.setState({
        disabled: false,
        error: coupon.error,
      });
    } else {
      this.setState({
        validated: true,
        disabled: true,
        error: null,
      });

      this.props.dispatch(addCoupon(coupon));
    }
  }

  handleChange = (event) => {
    const { target } = event;
    const { value, name } = target;
    this.setState({ [name]: value });
  }

  clearCoupon = () => {
    this.setState({
      validated: false,
      disabled: false,
      error: null,
      coupon: '',
    });

    this.props.dispatch(removeCoupon());
  }

  render() {
    const {
      coupon, showCouponForm, error, disabled, validated
    } = this.state;
    return (
      <div className="coupon">
        {!showCouponForm &&
          <Link onClick={() => this.setState({ showCouponForm: true })}>
            <span className="txt-grey">
              <FormattedMessage id="app.content.haveCoupon" defaultMessage="Have a coupon code?" />
            </span>
          </Link>
        }

        {showCouponForm &&
          <Form className="coupon-form" onSubmit={this.submitHandler}>
            <Label for="coupon-input">
              <FormattedMessage id="app.content.coupon" defaultMessage="Coupon code" />
            </Label>
            <FormGroup tag="fieldset">
              <Input
                type="text"
                name="coupon"
                id="coupon-input"
                value={coupon}
                onChange={this.handleChange}
                disabled={disabled}
                className="form-control coupon-input"
              />
              <Button color="primary" className="coupon-btn btn-md" onClick={this.submitHandler} disabled={disabled}>
                <FormattedMessage id="app.apply" defaultMessage="Apply" />
              </Button>
              {validated &&
                <Link onClick={() => this.clearCoupon()} className="txt-remove txt-grey">
                  <FormattedMessage id="app.content.coupon.remove" defaultMessage="remove" />
                </Link>
              }
              <br />
              {error &&
                <Alert color="danger">
                  <FormattedMessage id={error.id} defaultMessage={error.message} />
                </Alert>
              }
            </FormGroup>
          </Form>
        }
      </div>
    );
  }
}

CouponForm.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  paymentDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CouponForm));
