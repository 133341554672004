import indexOf from 'lodash/indexOf';
import without from 'lodash/without';
import uuidv4 from 'uuid/v4';
import * as stripe from 'src/api/stripe';
import * as payment from 'src/api/payment';
import * as checkout from 'src/api/checkout';

async function purchase(api, token, portalId, actor, lo, coupon, eventSessions) {
  return checkout.checkout(token, portalId, lo, coupon, eventSessions);
}

export default async function purchaseLo({
  portal, lo, cardInfo, creditCount, userEmails, coupon, isAdmin, eventSessions, actor, paymentApi
}) {
  const instanceName = portal.title;
  const selfPurchased = indexOf(userEmails, actor.mail) !== -1;
  const assignCredits = selfPurchased ? creditCount - 1 : creditCount;
  const assignLearners = selfPurchased ? without(userEmails, actor.mail) : userEmails;

  await stripe.setPublishableKey(instanceName);
  let token = await stripe.createToken(cardInfo);

  if (isAdmin && userEmails.length > 0) {
    if (selfPurchased) {
      await purchase(paymentApi, token, portal.id, actor, lo, coupon, eventSessions);
    }

    if (assignCredits > 0) {
      if (selfPurchased) {
        // After actor enrolled into course with old token, we need new token to buy credits.
        token = await stripe.createToken(cardInfo);
      }
      const credits = await payment.purchaseCredits(instanceName, token, lo.id, assignCredits, coupon);
      if (assignLearners.length) {
        return payment.bulkAssignCredits(instanceName, lo.id, assignLearners, credits);
      }
    }
  } else if (creditCount === 1 && selfPurchased) {
    return purchase(paymentApi, token, portal.id, actor, lo, coupon, eventSessions);
  } else {
    return payment.purchaseCredits(instanceName, token, lo.id, creditCount, coupon);
  }

  return true;
}
