import React from 'react';
import PropTypes from 'prop-types';
import Color from "color";
import { ButtonFilled, View, foundations } from '@go1d/go1d';


export function PaymentContainer({ children, cssProps }) {
  const props = {
    backgroundColor: 'soft',
    borderColor: 'highlight',
    border: 1,
    paddingX: 4,
    paddingTop: 5,
    paddingBottom: 3,
    borderRadius: 3,
    marginBottom: 4,
    [foundations.breakpoints.lg]: {
      paddingX: 6,
      marginBottom: 6,
    },
    ...cssProps,
  };

  return (
    <View
      borderRadius={3}
      paddingTop={5}
      paddingBottom={3}
      border={1}
      paddingX={4}
      css={{
        ...cssProps,
        backgroundColor: foundations.colors.soft,
        borderColor: foundations.colors.highlight,
        marginBottom: foundations.spacing[4],
        [foundations.breakpoints.lg]: {
          paddingLeft: foundations.spacing[6],
          paddingRight: foundations.spacing[6],
          marginBottom: foundations.spacing[6],
        },
      }}
    >
      {children}
    </View>
  );
}

PaymentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  cssProps: PropTypes.objectOf(PropTypes.any),
};

PaymentContainer.defaultProps = {
  cssProps: null,
};

export const PaymentSubmit = ({ children, disabled, primaryColor }) => {
  const hoverColor = Color(primaryColor || foundations.colors.accent)
    .darken(0.2)
    .rgb()
    .string();
  return (
    <ButtonFilled
      type="submit"
      width="100%"
      height="44"
      justifyContent="center"
      disabled={disabled}
      css={{
        backgroundColor: primaryColor || foundations.colors.accent,
        color: 'white',
        '&:hover, &:active': {
          backgroundColor: hoverColor,
        }
      }}
    >
      {children}
    </ButtonFilled>
  );
};

PaymentSubmit.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
  primaryColor: PropTypes.string,
};

PaymentSubmit.defaultProps = {
  primaryColor: null,
};
