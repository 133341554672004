import React from 'react';
import { injectIntl } from 'react-intl';

export function action(type, error = false, meta) {
  return (payload) => ({
    type, payload, error, meta,
  });
}

export const injectGetMessage = (fn, key) => React.createElement(injectIntl(({ intl }) => fn(intl.formatMessage)), { key });
