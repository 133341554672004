import includes from 'lodash/includes';
import find from 'lodash/find';
import * as actions from './actions';
import { storeLocal, deleteUserLocal } from '../../api';
import { setCredentials } from '../../common/axiosConfig';

const INITIAL_STATE = {
  user: {},
  portal: {},
  account: {},
  permissions: {
    isAdmin: false,
    isAuthor: false,
    isManager: false,
  },
  authenticated: false,
};

function computePermissions(state) {
  const { user, portal, permissions } = state;

  const account = find(user.accounts, item => item.instance === portal.title) || {};
  const roles = account.roles || [];
  const isAdmin = includes(roles, 'administrator');
  const isManager = includes(roles, 'manager');

  return {
    ...state,
    account,
    permissions: {
      ...permissions,
      isAdmin,
      isManager,
    }
  };
}

function computeAuthorPermissions(state, authors) {
  const { user, permissions } = state;
  const found = find(authors, author => Number(author.id) === Number(user.id));
  if (!found) {
    return { ...state };
  }

  return {
    ...state,
    permissions: {
      ...permissions,
      isAuthor: true,
    }
  };
}

export function userReducer(state = { ...INITIAL_STATE }, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCEEDED:
      // store user for later
      storeLocal(action.payload.token);
      setCredentials(action.payload.token);
      return computePermissions({
        ...state,
        user: { ...action.payload.user, jwt: action.payload.token },
        authenticated: action.payload.authenticated,
        enrolled: !!action.payload.enrolled,
      });

    case actions.LOGOUT:
      // store user for later
      deleteUserLocal();
      return INITIAL_STATE;

    case actions.UPDATE_PORTAL_INFO:
      return computePermissions({
        ...state,
        portal: {
          ...state.portal,
          ...action.payload,
        }
      });

    case actions.UPDATE_AUTHOR_PERMISSION:
      return computeAuthorPermissions({ ...state }, action.payload);

    case actions.GET_PORTAL_KEY:
      return {
        ...state,
        portal: {
          ...state.portal,
          key: action.payload,
        },
      };

    default:
      return state;
  }
}

export default userReducer;
