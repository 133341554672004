import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate-html';
import queryString from 'query-string';
import { Price } from '../common/Price';
import { FormattedMessage } from 'react-intl';
import { EventSession } from '../EventSession/EventSession';
import SeatCounter from '../SeatCounter/SeatCounter';
import CourseAssign from '../CourseAssign/CourseAssign';
import CouponForm from '../CouponForm/CouponForm';
import PaymentSumary from '../PaymentSumary/PaymentSumary';

import './CoursePreview.scss';
import { ButtonFilled, View, foundations } from '@go1d/go1d';

export const CoursePreview = ({
  lo,
  user,
  redirectUrl,
  exploreUrl,
  eventSessions,
  paymentDetail,
  portalId,
  viewOnly,
  headerMessage,
  hasPermission,
  isSelfPurchased,
  userUrl,
  location,
}) => {
  const headerInfo = (
    <View className="header-info">
      <View
        flexGrow={1}
        flexDirection="row"
        maxWidth="80%"
      >
        {lo.image &&
          <View maxWidth="30%">
            <div className="header-info-image" style={{ backgroundImage: `url(${lo.image})` }} />
          </View>
        }
        <View flexGrow={1} maxWidth={lo.image ? "70%" : "100%"}>
          <h3 className="header-info-title">{lo.title}</h3>
          <p className="author header-info-subtitle">
            <FormattedMessage id="app.content.instructedBy" defaultMessage="Instructed by {author}" values={{ author: lo.author }} />
          </p>
        </View>
      </View>
      <View textAlign="right" width="20%">
        <Price
          price={paymentDetail.basePrice}
          currency={paymentDetail.currency}
        />
        <p className="author header-info-subtitle">
          <FormattedMessage id="app.content.perPerson" defaultMessage="per person" />
        </p>
      </View>
    </View>
  );
  const query = location ? queryString.parse(location.search) : null;
  const eventInfo = eventSessions && eventSessions.map(eventSession =>
    <EventSession key={eventSession.id} eventSession={eventSession} />
  );
  const purchasedSeatCount = paymentDetail.seatCount;
  const assignedSeatCount = paymentDetail.selectedUsers.length;

  const learnerBuyCredits = !hasPermission && (paymentDetail.seatCount > 1 || (purchasedSeatCount === 1 && assignedSeatCount === 0));

  const assignedAllSeats = (assignedSeatCount === 0) || (purchasedSeatCount === assignedSeatCount && assignedSeatCount > 0);
  const notAssignedAny = (purchasedSeatCount > 0 && assignedSeatCount === 0) || learnerBuyCredits;
  const assignedForOthers = (assignedSeatCount === 1 && !isSelfPurchased) || assignedSeatCount > 1;
  const hasEvents = (lo.events && lo.events.length > 0 && query && query.eventSessionIds);

  return (
    <React.Fragment>
      <div className="content-detail">
        <div className={`course-preview card ${viewOnly ? 'center' : ''}`}>
          {viewOnly && headerMessage}
          {headerInfo}
          {hasEvents && eventSessions && eventSessions.length > 0 && (
            eventInfo
          )}
          {hasEvents && eventSessions && eventSessions.length === 0 &&
            <View
              borderBottom={1}
              borderColor="soft"
              paddingY={4}
              flexDirection="column"
            >
              <View
                backgroundColor="muted"
                height={16}
                marginBottom={3}
                maxWidth="80%"
              />
              <View
                backgroundColor="muted"
                height={16}
                marginBottom={5}
                maxWidth="60%"
              />
              <View
                backgroundColor="muted"
                height={16}
                marginBottom={3}
                maxWidth="80%"
              />
            </View>
          }
          {!viewOnly && hasPermission && <SeatCounter />}

          <React.Fragment>
            {viewOnly && hasEvents &&
              <div className="user-info">
                <h3>
                  <span id="assigned-seat-text">
                    <FormattedMessage
                      id="app.content.purchasedSeat"
                      defaultMessage="{seats, plural, one { Purchased 1 seat } other { Purchased # seats }}"
                      values={{ seats: purchasedSeatCount }}
                    />
                  </span>
                  {!assignedAllSeats && !learnerBuyCredits &&
                    <span>
                      <span>, </span>
                      <FormattedMessage
                        id="app.content.assignedSeat"
                        defaultMessage="{seats, plural, one { 1 seat was assigned for } other { # seats were assigned for }}"
                        values={{ seats: assignedSeatCount }}
                      />
                    </span>
                  }
                  {notAssignedAny && userUrl &&
                    <div className="txt-grey txt-sm m-t-sm" id="credits-info">
                      <FormattedMessage
                        id="app.content.assignLater"
                        defaultMessage="You can assign purchased seat(s) from { where }"
                        values={{
                          where: (
                            <button
                              className="link"
                              onClick={() => window.open(userUrl, '_top')}
                            >
                              <FormattedMessage id="app.content.yourAccount" defaultMessage="your account" />
                            </button>
                          )
                        }}
                      />
                    </div>
                  }
                </h3>
              </div>
            }
            <Truncate
              className="description"
              lines={8}
              dangerouslySetInnerHTML={{
                __html: lo.desc,
              }}
            />
            {!learnerBuyCredits &&
              <React.Fragment>
                <CourseAssign
                  portalId={portalId}
                  defaultUser={user}
                  viewOnly={viewOnly}
                  purchasedSeatCount={purchasedSeatCount}
                />
                {viewOnly && assignedForOthers &&
                  <div className="txt-grey txt-sm m-t-sm">
                    <FormattedMessage
                      id="app.content.assignedConfirmation"
                      defaultMessage="A confirmation email has been sent to each of the above people. They will be able to choose their own dates."
                    />
                  </div>
                }
              </React.Fragment>
            }
            <div className="divider" />
          </React.Fragment>
          <footer>
            <div>
              <PaymentSumary />
              {!viewOnly && <CouponForm />}
              {viewOnly && redirectUrl &&
                <ButtonFilled
                  width="100%"
                  justifyContent="center"
                  marginTop="20px"
                  paddingTop="10px"
                  paddingBottom="10px"
                  color="accent"
                  onClick={() => redirectUrl && window.open(redirectUrl, '_top')}
                >
                  {isSelfPurchased && !learnerBuyCredits &&
                    <FormattedMessage id="app.content.startLearning" defaultMessage="Start learning" />
                  }
                  {(!isSelfPurchased || learnerBuyCredits) &&
                    <FormattedMessage id="app.content.returnToLearning" defaultMessage="Return to learning" />
                  }
                </ButtonFilled>
              }
            </div>
          </footer>
        </div>
      </div>
      {viewOnly && exploreUrl &&
        <div className="explore-btn">
          <ButtonFilled
            width="100%"
            justifyContent="center"
            marginTop="20px"
            paddingTop="10px"
            paddingBottom="10px"
            onClick={() => exploreUrl && window.open(exploreUrl, '_top')}
          >
            <FormattedMessage id="app.content.exploreMore" defaultMessage="Explore more courses" />
          </ButtonFilled>
        </div>
      }
    </React.Fragment>
  );
};

CoursePreview.propTypes = {
  lo: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  eventSessions: PropTypes.arrayOf(PropTypes.any),
  paymentDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  portalId: PropTypes.number.isRequired,
  headerMessage: PropTypes.objectOf(PropTypes.any),
  viewOnly: PropTypes.bool,
  isSelfPurchased: PropTypes.bool,
  redirectUrl: PropTypes.string,
  exploreUrl: PropTypes.string,
  userUrl: PropTypes.string,
  hasPermission: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.any),
};

CoursePreview.defaultProps = {
  viewOnly: false,
  isSelfPurchased: false,
  headerMessage: null,
  redirectUrl: null,
  exploreUrl: null,
  userUrl: null,
  hasPermission: false,
  eventSessions: null,
  location: null,
};
