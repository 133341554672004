import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Alert, Form, FormGroup, Label } from 'reactstrap';
import { Select, TextInput } from '@go1d/go1d';

import { PaymentContainer, PaymentSubmit } from '../common';
import { requestPurchase, requestUserManagers } from '../../../api';
import * as actions from '../actions';


class RequestPurchase extends React.Component {
  static propTypes = {
    lo: PropTypes.objectOf(PropTypes.any).isRequired,
    portal: PropTypes.objectOf(PropTypes.any).isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
  };


  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      hasSuccess: false,
      isWorking: false,
      isLoading: true,
      managers: [],
      errorMessage: null,
    };
  }


  componentDidMount() {
    const { portal, user } = this.props;
    const account = find(user.accounts, item => item.instance === portal.title);
    requestUserManagers(account.instance, account.id)
      .then(list => {
        const managers = list.map(item => ({ label: item.name, value: item.root.id }));
        if (managers.length === 1) {
          this.setState({ managers, isLoading: false, requestFrom: managers[0].value });
        } else {
          this.setState({ managers, isLoading: false });
        }
      })
      .catch(() => this.setState({ managers: [], isLoading: false }));
  }


  requestPurchase() {
    const { lo, portal } = this.props;
    const { requestFrom } = this.state;

    return requestPurchase({
      instanceId: portal.id,
      loId: lo.id,
      managerId: requestFrom,
    });
  }


  handleChange = (event) => {
    const { target } = event;
    this.setState({
      [target.name]: target.value,
    });
  };


  submitForm = async (event) => {
    event.preventDefault();

    try {
      this.props.dispatch(actions.purchaseLearningProcessing());
      this.setState({ isWorking: true });
      await this.requestPurchase();
      this.setState({ hasSuccess: true });
      this.props.dispatch(actions.requestLearningDone());
    } catch (e) {
      this.setState({ hasError: true, errorMessage: e.response.data.message });
      this.props.dispatch(actions.purchaseLearningError());
    } finally {
      this.setState({ isWorking: false });
    }
  };


  render() {
    const { intl } = this.props;
    const {
      hasError, hasSuccess, isWorking, isLoading, managers, errorMessage
    } = this.state;

    const defaultText = intl.formatMessage({ id: 'app.payment.requestForm.selectManager' });

    if (isLoading) {
      return <FormattedMessage id="app.payment.requestForm.loadingManager" defaultMessage="Loading your managers…" />;
    }

    if (!isLoading && isEmpty(managers)) {
      return <FormattedMessage id="app.payment.requestForm.noManager" defaultMessage="You don't have any manager" />;
    }

    return (
      <Form onSubmit={this.submitForm}>
        <PaymentContainer>
          <FormGroup>
            <Label for="requestFrom">
              <FormattedMessage id="app.payment.requestFrom" defaultMessage="Request from" />
            </Label>
            {managers.length === 1 &&
              <TextInput id="requestFrom" name="requestFrom" value={managers[0].label} readOnly />
            }
            {managers.length > 1 &&
              <span className="resetSelectStyle">
                <Select
                  id="requestFrom"
                  name="requestFrom"
                  options={this.state.managers}
                  onChange={this.handleChange}
                  defaultText={defaultText}
                />
              </span>
            }
          </FormGroup>
        </PaymentContainer>

        <PaymentSubmit disabled={isWorking}>
          {!isWorking && (
            <FormattedMessage id="app.payment.requestPurchase" defaultMessage="Request purchase" />
          )}
          {isWorking && (
            <FormattedMessage id="app.processing" defaultMessage="Processing…" />
          )}
        </PaymentSubmit>

        {hasError && !errorMessage && (
          <Alert color="danger">
            <FormattedMessage
              id="app.payment.requestPurchase.failure"
              defaultMessage="Failed to request purchase."
            />
          </Alert>
        )}

        {hasError && errorMessage && (
          <Alert color="danger">
            {errorMessage}
          </Alert>
        )}

        {hasSuccess && (
          <Alert color="success">
            <FormattedMessage
              id="app.payment.requestPurchase.success"
              defaultMessage="Purchase requested successfully."
            />
          </Alert>
        )}
      </Form>
    );
  }
}

export default connect()(injectIntl(RequestPurchase));
