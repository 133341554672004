import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Price } from '../common/Price';
import { getPaymentDetail } from '../../../reducers';

import './PaymentSumary.scss';

/* istanbul ignore next */
const mapStateToProps = state => ({ paymentDetail: getPaymentDetail(state) });

const formatCoupon = coupon => {
  if (coupon.type === 'percent') {
    return `${coupon.value}%`;
  }
  return `$${coupon.value}`;
};


const renderTax = (tax, taxAmount, currency) => (
  <Row className="payment-sumary--cart">
    <Col xs="5" sm="7"className="label">
      <FormattedMessage id="app.payment.gst" defaultMessage="GST" />&nbsp;({tax}%)
    </Col>
    <Col xs="7" sm="5" className="amount">
      <Price price={taxAmount} currency={currency} />
    </Col>
  </Row>
);


const renderCoupon = (coupon, discountAmount, currency) => (
  <Row className="payment-sumary--cart">
    <Col xs="5" sm="7">
      <span className="label">
        <FormattedMessage id="app.payment.discount" defaultMessage="Discount" />&nbsp;
        <span className="detail">
          (<FormattedMessage
            id="app.payment.amountOff"
            defaultMessage="{amount} off"
            values={{ amount: formatCoupon(coupon) }}
          />)
        </span>
      </span>
      <div>
        <span className="sub-label">
          <FormattedMessage id="app.payment.coupon" defaultMessage="Coupon" />:&nbsp;
        </span>
        <span className="detail">{ coupon.title }</span>
      </div>
    </Col>
    <Col xs="7" sm="5" className="amount discount">
      -&nbsp;
      <Price price={discountAmount} currency={currency} />
    </Col>
  </Row>
);


export const PaymentSumary = ({
  paymentDetail,
}) => {
  const {
    totalAmount,
    currency,
    coupon,
    discountAmount,
    tax,
    taxAmount,
    subTotal,
  } = paymentDetail;
  return (
    <div className="payment-sumary">
      {(tax > 0 || coupon) &&
        <Row>
          <Col xs="5" sm="7" className="label">
            <FormattedMessage id="app.subTotal" defaultMessage="Sub total" />
          </Col>
          <Col xs="7" sm="5" className="amount">
            <Price price={subTotal} currency={currency} />
          </Col>
        </Row>
      }
      {tax > 0 &&
        renderTax(tax, taxAmount, currency)
      }
      {coupon &&
        renderCoupon(coupon, discountAmount, currency)
      }
      <Row className="payment-sumary--cart total">
        <Col xs="5" sm="7">
          <FormattedMessage id="app.total" defaultMessage="Total" />
        </Col>
        <Col xs="7" sm="5" className="amount">
          <Price price={totalAmount} currency={currency} />
        </Col>
      </Row>
    </div>
  );
};

PaymentSumary.propTypes = {
  paymentDetail: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(PaymentSumary);
