import React from 'react';
import PropTypes from 'prop-types';

const InlineAlert = ({
  text, variety, classNames, children,
}) => (
  <div className={`alert alert-${variety} ${classNames}`} role="alert">
    {text}
    {children}
  </div>
);

InlineAlert.defaultProps = {
  variety: 'success',
  classNames: '',
  children: null,
};

InlineAlert.propTypes = {
  text: PropTypes.string.isRequired,
  variety: PropTypes.string,
  classNames: PropTypes.string,
  children: PropTypes.node,
};

export default InlineAlert;
