import axios from 'axios';
import * as enroll from 'src/api/enroll';
import isEmpty from 'lodash/isEmpty';

async function checkout(tokenId, portalId, lo, coupon, eventSessions) {
  const courseEnrolmentId = await enroll.course(tokenId, portalId, lo, coupon);
  if (!isEmpty(eventSessions)) {
    return enroll.courseEvents(portalId, lo.id, coupon, eventSessions, courseEnrolmentId);
  }

  return courseEnrolmentId;
}

export {
  checkout
};
