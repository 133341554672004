import React, { Fragment } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { View, Container, foundations, Provider } from '@go1d/go1d';
import { injectGetMessage } from './utils';

import './App.css';
import EbanxForm from './components/payment/EbanxPayment';
import FreeForm from './components/free/FreeEnroll';
import PaymentForm from './components/PaymentForm/PaymentForm';
import Enrolled from './components/enrolled';
import {
  getUser,
  getAuthenticated,
  getPayment,
  getPaymentDetail,
  isEnrolled,
  isPaymentSucceeded,
  isPaymentProcessing,
  getPaymentMethod,
} from './reducers';
import LoginForm from './components/login/Login';
import ContentPreview from './components/content/Content';
import ContentSkeleton from './components/content/Skeleton';
import InlineAlert from './components/InlineAlert';
import * as paymentDetailActions from './components/payment/detail/actions';
import * as contentActions from './components/content/actions';
import * as actions from './components/login/actions';
import Register from './components/register';
import {
  fetchUser,
  retrieveLocal,
  storeLocal,
  requestLO,
  requestEventSessions,
  requestEnrolStatus,
  requestPortal,
  requestPaymentType,
  getJwtForApiKey,
} from './api';
import { decodeUserFromJwt } from './helpers/userHelper';
import './assets/scss/default.scss';
import './components/PaymentForm/PaymentForm.scss';

require('./assets/images/favicon.ico');

class App extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    isSucceeded: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    payment: PropTypes.objectOf(PropTypes.any),
    paymentDetail: PropTypes.objectOf(PropTypes.any),
    user: PropTypes.objectOf(PropTypes.any),
    dispatch: PropTypes.func.isRequired,
    paymentMethod: PropTypes.string.isRequired,
  };

  static defaultProps = {
    user: {},
    authenticated: false,
    payment: {},
    paymentDetail: {},
  };

  constructor(props) {
    super(props);

    const query = queryString.parse(this.props.location.search);
    this.props.dispatch(contentActions.setAvailableEventSessions(query.eventSessionIds));

    this.state = {
      portalId: Number(query.portal) || null,
      activeTab: 'register',
      lo: {
        title: 'Course not loaded',
        desc: 'Error.',
        price: '0',
        currency: 'AUD',
        author: null,
      },
      contentLoaded: false,
      paymentType: null,
      contentType: null,
      appOrigin: query.appOrigin || '*',
      redirectUrl: query.redirect,
      exploreUrl: query.exploreUrl,
      userUrl: query.userUrl,
      error: null,
      theme: null,
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const {
      lo,
      paymentType,
      jwt,
      learnerMail,
      apiKey,
      shareId,
    } = query;
    const isDefaultPayment = paymentType !== 'ebanx';
    // favour jwt query over localstorage
    if (jwt) { storeLocal(jwt); }
    const user = retrieveLocal() || {};
    const token = jwt || user.jwt || null;
    if (token) {
      const authenticated = true;
      let enrolled = false;
      requestEnrolStatus(lo, this.state.portalId, token)
        .then((status) => {
          if (!isDefaultPayment) {
            this.setState({ alreadyEnrolled: true });
            this.displayError(injectGetMessage(getMessage => getMessage({ id: 'error.already_enrolled' }), 'already_enrolled'));
          }
          enrolled = true;
        })
        .catch((err) => {
          console.log(err);
          if (!learnerMail) {
            this.props.dispatch(paymentDetailActions.addSelectedUser(user));
          }
        })
        .finally(() => {
          const decodeUser = decodeUserFromJwt(token);
          this.props.dispatch(actions.loginSucceeded({
            token,
            user: decodeUser,
            authenticated,
            enrolled,
          }));
        });
    }

    if (this.checkQuery(this.state.portalId, lo, paymentType)) {
      if (!token && apiKey) {
        getJwtForApiKey(apiKey)
          .then((response) => {
            this.props.dispatch(actions.swapApiKeyForJwt(response.jwt));
            this.requestCourseDetails(this.state.portalId, lo, shareId, paymentType, response.jwt, learnerMail);
          });
      } else {
        this.requestCourseDetails(this.state.portalId, lo, shareId, paymentType, token, learnerMail);
      }
    }
  }

  onLogout = (e) => {
    e.preventDefault();
    this.props.dispatch(actions.logout());
    this.setState({ error: null });
  };

  getPrimaryColor() {
    const { theme } = this.state;
    const query = queryString.parse(this.props.location.search);
    const { themed } = query;
    return theme && themed ? theme.primary : foundations.colors.accent;
  }

  checkQuery(portalId, loid, paymentType) {
    if (portalId === '' || portalId == null) {
      this.setState({
        error: injectGetMessage(getMessage => getMessage({ id: 'error.invalid_portal' }), 'invalid_portal'),
      });
      return false;
    } else if (loid === '' || loid == null) {
      this.setState({
        error: injectGetMessage(getMessage => getMessage({ id: 'error.invalid_lo' }), 'invalid_lo'),
      });
      return false;
    } else if (paymentType !== undefined && paymentType !== 'ebanx') {
      this.setState({
        error: injectGetMessage(getMessage => getMessage({ id: 'error.invalid_pay_type' }), 'invalid_pay_type'),
      });
      return false;
    }
    return true;
  }

  async checkPaymentType(portal, paymentType) {
    try {
      const response = await requestPaymentType(portal.title);
      if (response.data !== paymentType) {
        const error = injectGetMessage(getMessage => getMessage({ id: 'error.invalid_pay_type_content' }));
        this.setState({ error });
      }
    } catch (e) {
      const error = injectGetMessage(getMessage => getMessage({ id: 'error.invalid_pay_type' }));
      this.setState({ error });
    }
  }

  requestLearner(portalName, mail) {
    fetchUser(portalName, mail)
      .then(user => {
        if (user) {
          this.props.dispatch(paymentDetailActions.addSelectedUser(user));
        } else {
          this.setState({
            error: injectGetMessage(getMessage => getMessage({ id: 'error.loading_learner' }), 'loading_learner'),
          });
        }
      })
      .catch((err) => this.setState({
        error: injectGetMessage(getMessage => getMessage({ id: 'error.loading_learner' }), 'loading_learner'),
      }));
  }

  requestCourseDetails(portalId, loid, shareId, payType, jwt, learnerMail) {
    requestPortal(portalId)
      .then((portal) => {
        const { theme } = portal.data;
        if (theme) {
          if (portal.files) {
            theme.background = portal.files.login_background;
            theme.logo = portal.files.logo;
          }
          this.setState({ theme });
        }
        this.props.dispatch(actions.updatePortalInfo(portal));

        if (learnerMail) {
          this.requestLearner(portal.title, learnerMail);
        }

        if (payType !== undefined) {
          this.checkPaymentType(portal, payType);
        }

        requestLO(loid, shareId, portalId, jwt)
          .then((lo) => {
            this.props.dispatch(contentActions.requestContentSucceeded(lo));
            this.props.dispatch(paymentDetailActions.setPrice(lo.pricing));
            // @Temporary disabled: authors right now can not buy bulk credits throw exim
            // this.props.dispatch(actions.updateAuthorPermission(lo.authors));
            let { paymentType } = queryString.parse(this.props.location.search);
            if (lo.pricing && lo.pricing.total === 0) {
              paymentType = 'free';
            }
            const contentType = lo.events && lo.events.length > 0 ? 'event' : 'course';
            this.setState({
              contentLoaded: true,
              contentType,
              paymentType,
            });

            requestEventSessions(lo, jwt)
              .then(eventSessions => this.props.dispatch(contentActions.requestContentEventsSucceeded(eventSessions)));
          })
          .catch((err) => {
            if (err.response && err.response.data && err.response.status === 403 && err.response.data.message && err.response.data.message === 'Missing or invalid JWT.') {
              console.log('User needs to authenticate');
              this.setState({
                contentLoaded: true,
              });
            } else {
              this.setState({
                error: injectGetMessage(getMessage => getMessage({ id: 'error.loading_content' }), 'loading_content'),
              });
            }
          });
      })
      .catch((err) => {
        this.setState({
          error: injectGetMessage(getMessage => getMessage({ id: 'error.loading_portal' }), 'loading_portal'),
        });
      });
  }

  displayError = message => this.setState({ error: message });

  alreadyEnrolled = bool => this.setState({ alreadyEnrolled: bool });

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  renderError() {
    if (this.state.alreadyEnrolled) {
      return [
        <InlineAlert key="error-panel" text="" variety="danger">
          {this.state.error}
        </InlineAlert>,
        <a href="#" key="logout-link" className="change-user" onClick={this.onLogout}>Change User</a>,
      ];
    }
    return <InlineAlert text="" variety="danger">{this.state.error}</InlineAlert>;
  }


  renderPaymentForm(isProcessing) {
    const { user } = this.props;
    const {
      appOrigin, lo, portalId, paymentType,
    } = this.state;
    const primaryColor = this.getPrimaryColor();

    switch (paymentType) {
      case 'ebanx':
        return <EbanxForm authenticated portalID={portalId} user={user} lo={lo} color={primaryColor} loading={isProcessing} />;
      case 'free':
        return <FreeForm authenticated user={user} lo={lo} color={primaryColor} loading={isProcessing} />;
      default:
        return <PaymentForm color={primaryColor} appOrigin={appOrigin} loading={isProcessing} />;
    }
  }


  renderMain() {
    const {
      authenticated, payment, isProcessing, paymentMethod, location, enrolled, isAdmin, isManager
    } = this.props;
    const {
      lo, portalId, contentType, paymentType, contentLoaded
    } = this.state;

    const primaryColor = this.getPrimaryColor();
    const isDefaultPayment = !paymentType;
    const canPurchaseCredits = !!(isAdmin || isManager);
    return (
      <React.Fragment>
        <Provider accent={primaryColor}>
          <Container contain="wide" paddingX={4}>
            {!this.state.error &&
            <View
              justifyContent="space-between"
              css={{
                flexDirection: "column",
                [foundations.breakpoints.lg]: {
                  flexDirection: "row"
                }
              }}
            >
              {enrolled && !canPurchaseCredits &&
                <View
                  css={{
                    width: "100%",
                    [foundations.breakpoints.lg]: {
                      width: "48%",
                    }
                  }}
                >
                  <Enrolled existing={true} redirectUrl={queryString.parse(this.props.location.search).redirect} />
                </View>
              }

              {!(enrolled && !canPurchaseCredits) && payment && payment.enrolled === undefined &&
                <View
                  css={{
                    width: "100%",
                    [foundations.breakpoints.lg]: {
                      width: "48%",
                    }
                  }}
                >
                  {!contentLoaded &&
                    <ContentSkeleton />
                  }
                  {contentLoaded &&
                    <ContentPreview portalId={portalId} lo={lo} contentType={contentType} paymentSucceeded={false} location={location} />
                  }
                </View>}
              {authenticated && payment && payment.enrolled &&
                <Enrolled redirectUrl={queryString.parse(this.props.location.search).redirect} />
              }
              <View
                marginBottom={4}
                css={{
                  width: "100%",
                  [foundations.breakpoints.lg]: {
                    width: "48%",
                  }
                }}
              >
                {!(enrolled && !canPurchaseCredits) && authenticated && payment && payment.enrolled === undefined &&
                  this.renderPaymentForm(isProcessing)
                }
                {!authenticated &&
                  <Fragment>
                    <Nav tabs className="app-login">
                      <NavItem>
                        <NavLink
                          active={this.state.activeTab === 'register'}
                          onClick={() => this.toggle('register')}
                        >
                          <FormattedMessage id="app.register" defaultMessage="Register" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={this.state.activeTab === 'login'}
                          onClick={() => this.toggle('login')}
                        >
                          <FormattedMessage id="app.signIn" defaultMessage="Sign In" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="register" className="pt-3">
                        <Register portalID={portalId} color={primaryColor} {...this.props} />
                      </TabPane>
                      <TabPane tabId="login" className="pt-3">
                        <LoginForm portalID={portalId} error={this.displayError} after={this.alreadyEnrolled} color={primaryColor} />
                      </TabPane>
                    </TabContent>
                  </Fragment>
                }
              </View>
            </View>
          }
            {this.state.error &&
              this.renderError()
            }
          </Container>
        </Provider>
      </React.Fragment>
    );
  }


  render() {
    const query = queryString.parse(this.props.location.search);
    const { themed } = query;
    const {
      lo, portalId, contentType, redirectUrl, exploreUrl, paymentType, userUrl, theme
    } = this.state;
    const { isSucceeded, enrolled } = this.props;
    const isDefaultPayment = paymentType !== 'ebanx';
    const mainCss = {
      backgroundColor: isDefaultPayment ? "#F1F6F9" : "white",
      backgroundImage: themed && theme && theme.background ? `url('${theme.background}')` : 'none',
      backgroundSize: 'cover',
    };
    const logoCss = {
      backgroundImage: themed && theme && theme.logo ? `url('${theme.logo}')` : `url('images/logo.png')`,
      width: "auto",
    };
    return (
      <section id="main" style={{ ...mainCss }}>
        {isDefaultPayment &&
          <div className="app-logo" style={{ ...logoCss }} />
        }
        {isSucceeded &&
          <Container contain="wide" alignItems="center" paddingX={4}>
            <View
              css={{
                width: "100%",
                [foundations.breakpoints.lg]: {
                  width: "48%",
                }
              }}
            >
              <ContentPreview
                portalId={portalId}
                lo={lo}
                contentType={contentType}
                redirectUrl={redirectUrl}
                exploreUrl={exploreUrl}
                userUrl={userUrl}
              />
            </View>
          </Container>
        }
        {!isSucceeded && this.renderMain()}
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  authenticated: getAuthenticated(state),
  isSucceeded: isPaymentSucceeded(state),
  isProcessing: isPaymentProcessing(state),
  paymentMethod: getPaymentMethod(state),
  payment: getPayment(state),
  paymentDetail: getPaymentDetail(state),
  user: getUser(state),
  enrolled: isEnrolled(state),
  isAdmin: state.auth && state.auth.permissions && state.auth.permissions.isAdmin,
  isManager: state.auth && state.auth.permissions && state.auth.permissions.isManager,
});

// make App hot reloadable
export default hot(module)(withRouter(connect(mapStateToProps)(App)));
