import * as stripe from 'src/api/stripe';
import * as enroll from 'src/api/enroll';

export default async function purchaseLi({
  portal, lo, cardInfo, coupon
}) {
  await stripe.setPublishableKey(portal.title);
  const token = await stripe.createToken(cardInfo);

  return enroll.course(token, portal.id, lo, coupon);
}
