
import { action } from '../../utils';


export const REQUEST_CONTENT = 'REQUEST/CONTENT';
export const REQUEST_CONTENT_SUCCEEDED = 'REQUEST/CONTENT/SUCCEEDED';
export const REQUEST_CONTENT_FAILED = 'REQUEST/CONTENT/FAILED';

export const REQUEST_CONTENT_EVENTS = 'REQUEST/CONTENT/EVENTS';
export const REQUEST_CONTENT_EVENTS_SUCCEEDED = 'REQUEST/CONTENT/EVENTS/SUCCEEDED';
export const REQUEST_CONTENT_EVENTS_FAILED = 'REQUEST/CONTENT/EVENTS/FAILED';

export const SET_AVAILABLE_EVENT_SESSIONS = 'SET/AVAILABLE_EVENT_SESSIONS';

export const requestContent = action(REQUEST_CONTENT);
export const requestContentSucceeded = action(REQUEST_CONTENT_SUCCEEDED);
export const requestContentFailed = action(REQUEST_CONTENT_FAILED, true);

export const requestContentEvents = action(REQUEST_CONTENT_EVENTS);
export const requestContentEventsSucceeded = action(REQUEST_CONTENT_EVENTS_SUCCEEDED);
export const requestContentEventsFailed = action(REQUEST_CONTENT_EVENTS_FAILED, true);

export const setAvailableEventSessions = action(SET_AVAILABLE_EVENT_SESSIONS);
