import flatten from 'lodash/flatten';
import map from 'lodash/map';

class CourseHelper {
  createFromResponse = (lo, expand) => {
    let modules = [];
    let events = [];

    modules = lo.items && lo.items.filter(item => item.type === 'module');
    if (modules) {
      const items = map(modules, module => map(module.items, item => ({
        ...item,
        moduleId: Number(module.id),
        id: Number(item.id),
      })));

      const childLIs = flatten(items);

      events = childLIs.filter(item => item.type === 'event');
    }

    const pricing = {
      price: lo.pricing.price,
      currency: lo.pricing.currency,
      tax: lo.pricing.tax,
      taxIncluded: lo.pricing.tax_included,
    };

    const basicInfo = {
      id: Number(lo.id),
      title: lo.title,
      image: lo.image || (lo.data && lo.data.image),
      desc: lo.description,
      type: lo.type,
      price: lo.pricing ? lo.pricing.price : 0,
      currency: lo.pricing ? lo.pricing.currency : null,
      pricing,
      author: lo.authors ? `${lo.authors[0].first_name} ${lo.authors[0].last_name}` : null,
      portalId: Number(lo.instance_id) || null,
      authors: lo.authors,
      data: lo.data || {},
      tags: lo.tags || [],
      modules,
      events,
    };

    if (expand !== undefined) {
      return {
        ...basicInfo,
        ...expand,
      }
    }

    return basicInfo;
  };
}

export const courseHelper = new CourseHelper();
