import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import scriptLoader from 'react-async-script-loader';
import BlockUi from 'react-block-ui';
import NumberFormat from 'react-number-format';
import { Loader } from 'react-loaders';
import { Input, Form, FormGroup, Label, Button, FormFeedback, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import MaskedInput from 'react-text-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcAmex, faCcDiscover, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import * as actions from './actions';
import { getUser, getAuthenticated } from '../../reducers';
import InlineAlert from '../InlineAlert';
import { requestEnrolLOPaid } from '../../api/index';
import { getLO } from '../content/reducers';
import { validation, constraints } from './validate';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    user: getUser(state),
    isAuthenticated: getAuthenticated(state),
    lo: getLO(state),
  };
}

export class EbanxForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    lo: PropTypes.objectOf(PropTypes.any).isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    isScriptLoaded: PropTypes.bool,
    isScriptLoadSucceed: PropTypes.bool,
    color: PropTypes.string,
    intl: intlShape.isRequired,
    portalID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }

  static defaultProps = {
    isScriptLoaded: false,
    isScriptLoadSucceed: false,
    color: '#31b8da',
  }

  constructor(props) {
    super(props);
    this.state = {
      ebanxError: null,
      ebanxReady: false,
      name: '',
      email: '',
      phone_number: '',
      document: '',
      street_number: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      ccName: '',
      ccNumber: '',
      ccExpiry: '',
      ccCVV: '',
      // form state
      formErrors: {
        name: '',
        email: '',
        phone_number: '',
        document: '',
        street_number: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        ccName: '',
        ccNumber: '',
        ccExpiry: '',
        ccCVV: '',
      },
      formState: {
        name: null,
        email: null,
        phone_number: null,
        document: null,
        street_number: null,
        address: null,
        city: null,
        state: null,
        zipcode: null,
        country: null,
        ccName: null,
        ccNumber: null,
        ccExpiry: null,
        ccCVV: null,
      },
      error: false,
      ipLocation: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.inputValid = this.inputValid.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  /* istanbul ignore next */
  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) { // load finished
      if (isScriptLoadSucceed) {
        if (process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'qa') {
          window.EBANX.config.setMode('test');
          window.EBANX.config.setPublishableKey('test_pk_2nab58dlc0ioJOvYYg2kVg');
        } else {
          window.EBANX.config.setMode('production');
          window.EBANX.config.setPublishableKey('live_pk_1aUNY7U9VGzGDgXcM3CGew');
        }
        this.setState(
          { ebanxReady: true },
          () => this.prefillForm(),
        );
        /* getUserLoc().then((response) => {
          this.setState(
            { ebanxReady: true, ipLocation: response },
            () => this.prefillForm(),
          );
           try {
            if (process.env.NODE_ENV !== 'dev') {
              window.EBANX.config.setCountry(response.countryCode.toLowerCase());
            }
          } catch (error) {
            console.log(error);
            this.setState({ ebanxError: 'Unfortunately, this payment type is not supported in your country.' });
          }
        }).catch((err) => {
        }); */
      }
    }
  }

  prefillForm() {
    const { name } = this.props.user;
    const email = this.props.user.mail ? this.props.user.mail : '';
    // const state = this.state.ipLocation.region ? this.state.ipLocation.region : '';
    // const city = this.state.ipLocation.city ? this.state.ipLocation.city : '';
    // const zipcode = this.state.ipLocation.zip ? this.state.ipLocation.zip : '';
    // const country = this.state.ipLocation.countryCode ? this.state.ipLocation.countryCode : '';

    this.setState({
      name,
      email,
      country: 'br', // state, city, country, zipcode,
    });
  }

  handleChange = (event) => {
    const { target } = event;
    const { value, name } = target;
    let val = value;
    if (name === 'country' && window.EBANX) {
      window.EBANX.config.setCountry(value);
    }
    this.setState({ [name]: value });


    if (name === 'ccExpiry') {
      if (value && (value.includes('Y') || value.includes('M'))) {
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            ccExpiry: [<FormattedMessage id="error.cc_expiry_invalid" defaultMessage="Card expiry is invalid. " />],
          },
        });
        return;
      }
    }

    if (name === 'ccExpiry' && value.length > 2) {
      const date = val ? val.split('/') : val;
      val = `${date[1]}/${date[0]}`;
    }

    const field = { [name]: val };
    const err = validation(field, { [name]: constraints[name] });
    if (err) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          ...err,
        },
      });
    } else {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          [name]: '',
        },
      });
    }
  }

  submitHandler = async (e) => {
    e.preventDefault();
    this.setState({ error: null });
    const {
      name, phone_number, email, document,
      street_number, address, city, state, zipcode, country,
      ccName, ccNumber, ccCVV, ccExpiry,
    } = this.state;

    const date = ccExpiry ? ccExpiry.split('/') : ccExpiry;

    const err = validation({
      name,
      phone_number,
      email,
      document,
      street_number,
      address,
      city,
      state,
      zipcode,
      country,
      ccName,
      ccNumber,
      ccCVV,
      ccExpiry: date ? `${date[1]}/${date[0]}` : '',
    });

    this.setState({
      formErrors: {
        ...err,
      },
    });

    if (!err) {
      this.setState({ ebanxReady: false });
      /* istanbul ignore next */
      const tokenise = new Promise((resolve, reject) => {
        window.EBANX.card.createToken({
          card_number: this.state.ccNumber,
          card_name: this.state.ccName,
          card_due_date: `${this.state.ccExpiry}`,
          card_cvv: this.state.ccCVV,
        }, (ebanxResponse) => {
          const hasStatus = Object.prototype.hasOwnProperty.call(ebanxResponse.data, 'status');
          if (hasStatus) {
            resolve({ payment_type: ebanxResponse.data.payment_type_code, token: ebanxResponse.data.token });
          } else {
            const errorMessage = ebanxResponse.error.err.status_message || ebanxResponse.error.err.message;
            reject(errorMessage);
          }
        });
      });

      try {
        const ebanxQuery = await tokenise;
        this.setState({ ebanxReady: true });
        const { jwt } = this.props.user;
        const { id } = this.props.lo;
        const { portalID } = this.props;

        const resp = await requestEnrolLOPaid(id, portalID, jwt, {
          token: ebanxQuery.token,
          email,
          full_name: name,
          payment_type: ebanxQuery.payment_type,
          document,
          zip_code: zipcode,
          address,
          street_number,
          city,
          state,
          country,
          phone_number,
        });

        // mock response for test/dev
        // const resp = await new Promise((resolve, reject) => {
        //   setTimeout(() => {
        //     resolve({ id: '123' });
        //   }, 300);
        // });
        this.props.dispatch(actions.enrolSucceeded(this.props.user, this.props.lo, resp));
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.status_message || error.response.data.message;
          this.setState({ error: errorMessage, ebanxReady: true });
        } else {
          const errorMessage = this.props.intl.formatMessage({ id: 'app.invalidCreditCard' });
          this.setState({ error: errorMessage, ebanxReady: true });
        }
      }
    } else {
      Object.entries(this.state.formErrors).forEach(([fieldname, error]) => {
        const formState = { ...this.state.formState };
        formState[fieldname] = 'is-invalid';
        this.setState({ formState });
      });
    }
  }

  inputValid(name) {
    return this.state.formErrors[name]
      ? 'is-invalid form-control'
      : this.state[name] ? 'is-valid form-control' : 'form-control';
  }

  render() {
    const { price, currency } = this.props.lo;
    const bgColor = {
      background: this.props.color,
    };
    return (
      <div className="ebanx cc-payment">
        {!this.state.ebanxError &&
          <BlockUi
            tag="div"
            blocking={!this.state.ebanxReady}
            loader={<Loader active type="ball-pulse" color={this.props.color} />}
            className="loader"
          >
            <Form onSubmit={this.submitHandler}>
              <h3>
                <FormattedMessage id="app.billingDetails" defaultMessage="Billing Details" />
              </h3>
              <FormGroup tag="fieldset" className="billing-details">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="name" className=""><FormattedMessage id="app.name" defaultMessage="Name" /></Label>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        className={this.inputValid('name')}
                      />
                      <FormFeedback>{this.state.formErrors.name}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="email" className=""><FormattedMessage id="app.email" defaultMessage="Email" /></Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        className={this.inputValid('email')}
                      />
                      <FormFeedback>{this.state.formErrors.email}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="phone_number" className=""><FormattedMessage id="app.phone" defaultMessage="Phone" /></Label>
                      <MaskedInput
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        value={this.state.phone_number}
                        onChange={this.handleChange}
                        className={this.inputValid('phone_number')}
                      />
                      <FormFeedback>{this.state.formErrors.phone_number}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="document" className="">CPF</Label>
                      <MaskedInput
                        type="text"
                        name="document"
                        id="document"
                        mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]}
                        value={this.state.document}
                        onChange={this.handleChange}
                        className={this.inputValid('document')}
                      />
                      <FormFeedback>{this.state.formErrors.document}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="address">
                  <Label><FormattedMessage id="app.address" defaultMessage="Address" /></Label>
                  <Row>
                    <Col xs="2">
                      <FormattedMessage id="app.placeholders.streetNumber">
                        {
                          streetNumber => (
                            <Input
                              type="text"
                              name="street_number"
                              id="street_number"
                              placeholder={streetNumber}
                              value={this.state.street_number}
                              onChange={this.handleChange}
                              className={this.inputValid('street_number')}
                            />
                          )
                        }
                      </FormattedMessage>
                      <FormFeedback className="mb-3">{this.state.formErrors.street_number}</FormFeedback>
                    </Col>
                    <Col xs="6">
                      <FormattedMessage id="app.placeholders.streetName">
                        {
                          streetName => (
                            <Input
                              type="text"
                              name="address"
                              id="address"
                              placeholder={streetName}
                              value={this.state.address}
                              onChange={this.handleChange}
                              className={this.inputValid('address')}
                            />
                          )
                        }
                      </FormattedMessage>
                      <FormFeedback className="mb-3">{this.state.formErrors.address}</FormFeedback>
                    </Col>
                    <Col xs="4">
                      <FormattedMessage id="app.placeholders.city">
                        {
                          city => (
                            <Input
                              type="text"
                              name="city"
                              id="city"
                              placeholder={city}
                              value={this.state.city}
                              onChange={this.handleChange}
                              className={this.inputValid('city')}
                            />
                          )
                        }
                      </FormattedMessage>
                      <FormFeedback className="mb-3">{this.state.formErrors.city}</FormFeedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4" md="4" sm="12">
                      <Input
                        type="select"
                        name="state"
                        id="state"
                        placeholder="State"
                        value={this.state.state}
                        onChange={this.handleChange}
                        className={this.inputValid('state')}
                      >
                        <FormattedMessage id="app.selectState">
                          {
                            selectState => <option value="">{selectState} ..</option>
                          }
                        </FormattedMessage>
                        <optgroup label="Brasil">
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                        </optgroup>
                      </Input>
                      <FormFeedback className="mb-3">{this.state.formErrors.state}</FormFeedback>
                    </Col>
                    <Col lg="4" md="4" sm="6">
                      <FormattedMessage id="app.placeholders.zipCode">
                        {
                          zipCode => (
                            <MaskedInput
                              type="text"
                              name="zipcode"
                              id="zipcode"
                              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                              placeholder={zipCode}
                              value={this.state.zipcode}
                              onChange={this.handleChange}
                              className={this.inputValid('zipcode')}
                            />
                          )
                        }
                      </FormattedMessage>
                      <FormFeedback className="mb-3">{this.state.formErrors.zipcode}</FormFeedback>
                    </Col>
                    <Col lg="4" md="4" sm="6">
                      <Input
                        type="select"
                        name="country"
                        id="country"
                        placeholder="Country"
                        value={this.state.country}
                        onChange={this.handleChange}
                        className={this.inputValid('country')}
                      >
                        <FormattedMessage id="app.selectCountry">
                          {
                            selectCountry => <option value="">{selectCountry} ..</option>
                          }
                        </FormattedMessage>
                        <option value="br">
                          Brasil
                        </option>
                      </Input>
                      <FormFeedback className="mb-3">{this.state.formErrors.country}</FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>
              </FormGroup>
              <h3 className="ebanx-heading"><FormattedMessage id="app.securePayment" defaultMessage="Secure Payment" /></h3>
              <FormGroup tag="fieldset" className="ebanx-form">
                <span className="card-icons">
                  <FontAwesomeIcon icon={faCcVisa} />
                  <FontAwesomeIcon icon={faCcMastercard} />
                  <FontAwesomeIcon icon={faCcAmex} />
                  <FontAwesomeIcon icon={faCcDiscover} />
                  <span className="cc-icon hipercard" />
                  <span className="cc-icon elo" />
                  <span className="cc-icon aura" />
                </span>
                <h5>Credit Card</h5>
                <FormGroup>
                  <Label for="ccName" className=""><FormattedMessage id="app.cardName" defaultMessage="Name on card" /></Label>
                  <Input
                    type="text"
                    name="ccName"
                    id="ccName"
                    value={this.state.ccName}
                    onChange={this.handleChange}
                    className={this.inputValid('ccName')}
                  />
                  <FormFeedback>{this.state.formErrors.ccName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="ccNumber" className=""><FormattedMessage id="app.cardNumber" defaultMessage="Card number" /></Label>
                  <Input
                    type="number"
                    name="ccNumber"
                    id="ccNumber"
                    value={this.state.ccNumber}
                    onChange={this.handleChange}
                    className={this.inputValid('ccNumber')}
                  />
                  <FormFeedback>{this.state.formErrors.ccNumber}</FormFeedback>
                </FormGroup>
                <FormGroup className="cc">
                  <Label for="ccExpiry" className=""><FormattedMessage id="app.cardExpiry" defaultMessage="Card expiry" /></Label>
                  <div className="cc_exp">
                    <NumberFormat
                      name="ccExpiry"
                      id="ccExpiry"
                      allowEmptyFormatting={false}
                      value={this.state.ccExpiry}
                      onChange={this.handleChange}
                      format="##/####"
                      placeholder="MM/YYYY"
                      mask={['M', 'M', 'Y', 'Y', 'Y', 'Y']}
                      className={this.inputValid('ccExpiry')}
                    />
                    <FormFeedback>{this.state.formErrors.ccExpiry ? this.state.formErrors.ccExpiry[0] : null}</FormFeedback>
                  </div>
                </FormGroup>
                <FormGroup className="float-right cvv-form-group">
                  <Label for="ccCVV" className="">CVV</Label>
                  <Input
                    type="text"
                    name="ccCVV"
                    id="ccCVV"
                    maxLength="4"
                    value={this.state.ccCVV}
                    onChange={this.handleChange}
                    className={this.inputValid('ccCVV')}
                  />
                  <FormFeedback>{this.state.formErrors.ccCVV}</FormFeedback>
                </FormGroup>
              </FormGroup>
              {this.state.error && <InlineAlert variety="danger" text={this.state.error} />}
              <FormGroup>
                <Button color="primary" className="btn-lg" style={bgColor}>
                  <FormattedMessage id="app.purchaseFor" defaultMessage="Purchase for" /> {currency === 'BRL' ? `R$${price},00` : `$${price} ${currency}`}
                </Button>
              </FormGroup>
            </Form>
          </BlockUi>
        }
        {this.state.ebanxError &&
          <InlineAlert text={this.state.ebanxError} variety="danger" />
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(scriptLoader('https://js.ebanx.com/ebanx-1.5.min.js')(injectIntl(EbanxForm)));
