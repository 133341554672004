import React from 'react';
import PropTypes from 'prop-types';

import { ButtonMinimal } from '@go1d/go1d';

import './Counter.scss';

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export class Counter extends React.Component {
  constructor(props) {
    super(props);
    const { value, min, max } = props;
    const count = clamp(value, min, max);
    this.state = { count };
  }

  handleIncrement() {
    let { count } = this.state;
    const {
      onIncreasing, max
    } = this.props;

    if (count < max) {
      count += 1;
      onIncreasing(count);
      this.setState({ count });
    }
  }

  handleDecrement() {
    let { count } = this.state;
    const {
      onDecreasing, min
    } = this.props;

    if (count > min) {
      count -= 1;
      onDecreasing(count);
      this.setState({ count });
    }
  }

  render() {
    const { count } = this.state;

    return (
      <div className="counter">
        <span className="counter-action counter-action--left">
          <ButtonMinimal
            iconName="Minus"
            backgroundColor="background"
            paddingX={4}
            width="50px"
            height="50px"
            alignItems="center"
            onClick={() => this.handleDecrement()}
          />
        </span>
        <span className="counter--count">{ count }</span>
        <span className="counter-action counter-action--right">
          <ButtonMinimal
            iconName="Plus"
            backgroundColor="background"
            paddingX={4}
            width="50px"
            height="50px"
            alignItems="center"
            onClick={() => this.handleIncrement()}
          />
        </span>
      </div>
    );
  }
}

Counter.propTypes = {
  min: PropTypes.number,
  value: PropTypes.number,
  max: PropTypes.number,
  onIncreasing: PropTypes.func,
  onDecreasing: PropTypes.func,
};

Counter.defaultProps = {
  min: 0,
  value: 0,
  max: Infinity,
  onIncreasing: (count) => null,
  onDecreasing: (count) => null,
};
