import "@babel/polyfill";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-block-ui/style.css';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import blockUiMiddleware from 'react-block-ui/lib/reduxMiddleware';
import createHistory from 'history/createBrowserHistory';
import { ConnectedRouter, routerReducer, routerMiddleware, LOCATION_CHANGE } from 'react-router-redux';
import { createTracker } from 'redux-segment';
import { createMiddleware } from 'redux-beacon';
import { IntlProvider, addLocaleData } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_pt from 'react-intl/locale-data/pt';
import GoogleAnalytics, { trackPageView } from '@redux-beacon/google-analytics';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import messagesEN from './translations/en.json';
import messagesPT from './translations/pt.json';
import App from './App';

addLocaleData([...locale_en, ...locale_pt]);

const tracker = createTracker();

const sagaMiddleware = createSagaMiddleware();

// Google analytics redux beacon init
const eventsMap = {
  // events can be tracked as well
  // but the code must be imported above
  [LOCATION_CHANGE]: trackPageView(action => ({
    page: action.payload.pathname,
  })),
};

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory({ basename: '/' });
// Build the middleware for intercepting and dispatching navigation actions
const middleware = [
  sagaMiddleware,
  blockUiMiddleware,
  routerMiddleware(history),
  tracker,
];

const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer,
  }),
  composeWithDevTools(applyMiddleware(...middleware)),
);

const messages = {
  'pt': messagesPT,
  'en': messagesEN,
};
const language = navigator.language.split(/[-_]/)[0]; // language without region code

// sagaMiddleware.run(rootSaga);

const root = document.getElementById('root');
const load = () => render(
  (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <IntlProvider locale={language} messages={messages[language]}>
          <App />
        </IntlProvider>
      </ConnectedRouter>
    </Provider>
  ), root,
);

const initGA = (config) => {
  // Analytics
  // Creates an initial ga() function.
  // The queued commands will be executed once analytics.js loads.
  window.ga = window.ga || function (...rest) {
    (window.ga.q = window.ga.q || []).push(...rest);
  };

  // Sets the time (as an integer) this tag was executed.
  // Used for timing hits.
  window.ga.l = +new Date();

  // Creates a default tracker with automatic cookie domain configuration.
  window.ga('create', config.tracking_id, 'auto');

  // Sends a pageview hit from the tracker just created.
  // not needed, router registers a pageview for first load
  // window.ga('send', 'pageview');
};


initGA('UA-XXXXXX-XX');
// load configuration and delay mounting of App,
// to avoid at least one page flicker
// loadConfig('/config.json')
//   .then((config) => {
//     initGA(config.googleAnalytics);
//   })
//   .then(() => load());
load();

