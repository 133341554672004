import * as actions from './actions';

export function enrolReducer(state = {}, action) {
  switch (action.type) {
    case actions.ENROL_SUCCEEDED:
      return {
        payload: action.payload,
        enrolled: true,
      };
    case actions.ENROL_FAILED:
      return state;
    default:
      return state;
  }
}

export default enrolReducer;
