import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Counter } from '../common/Counter/Counter';
import { getPaymentDetail, getMaxEnrollCount } from '../../../reducers';
import * as paymentDetailActions from '../../payment/detail/actions';

import './SeatCounter.scss';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    paymentDetail: getPaymentDetail(state),
    maxSeats: getMaxEnrollCount(state),
  };
}

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({ dispatch });

export const SeatCounter = ({
  paymentDetail,
  maxSeats,
  dispatch,
}) => {
  const minSeats = Math.max(paymentDetail.selectedUsers.length, 1);
  return (
    <div className="seats-counter">
      <span className="seats-counter-title">
        <span className="seats-counter-title--big">
          <FormattedMessage id="app.content.seatsToPurchase" defaultMessage="Seats to purchase" />
        </span>
        <br />
        {maxSeats !== Infinity &&
          <span className="seats-counter-title--small txt-grey">
            <FormattedMessage
              id="app.content.availableSeats"
              defaultMessage="{seats, plural, one { 1 available seat } other { # available seats }}"
              values={{ seats: maxSeats }}
            />
          </span>
        }
      </span>
      <Counter
        value={paymentDetail.seatCount}
        min={minSeats}
        max={maxSeats}
        onIncreasing={() => dispatch(paymentDetailActions.addSeat())}
        onDecreasing={() => dispatch(paymentDetailActions.removeSeat())}
      />
    </div>
  );
};


SeatCounter.propTypes = {
  paymentDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  maxSeats: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeatCounter);
