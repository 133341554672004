class EventSessionHelper {
  createFromResponse = (blob) => {
    const { location } = blob;
    return {
      id: Number(blob.id),
      loId: Number(blob.lo_id),
      title: blob.title,
      description: blob.description,
      startAt: new Date(blob.start_at),
      endAt: new Date(blob.end_at),
      timezone: blob.timezone,
      location: {
        id: Number(location.id),
        portalId: Number(location.portal_id),
        title: location.title,
        isOnline: Boolean(Number(location.is_online)),
        thoroughfare: location.thoroughfare,
        premise: location.premise || null,
        locality: location.locality,
        administrativeArea: location.administrative_area,
        country: location.country,
        latitude: location.latitude,
        longitude: location.longitude,
        data: location.data || {},
      },
      locationId: Number(blob.location_id) || null,
      url: blob.url || null,
      attendeeLimit: Number(blob.attendee_limit),
      enrolledCount: Number(blob.enrolled),
      availableSeats: blob.available_seats ? Number(blob.available_seats) : Infinity,
      instructorIds: blob.instructor_ids,
      published: Boolean(blob.published),
    };
  }
}

export const eventSessionHelper = new EventSessionHelper();
