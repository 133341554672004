import axios from 'axios';
import map from 'lodash/map';
import * as stripe from 'src/api/stripe';

async function purchaseCredits(instanceName, tokenId, loId, creditCount, coupon) {
  const endpoint = `credit/purchase/${instanceName}/lo/${loId}/${creditCount}/0`;
  const data = {
    paymentMethod: 'stripe',
    coupon,
    paymentOptions: { token: tokenId },
  };

  const response = await axios.post(endpoint, data);
  return response.data;
}

function bulkAssignCredits(instanceName, loId, emails, credits) {
  const users = emails.map((mail, index) => {
    const { token } = credits[index];
    return { mail, token };
  });

  const endpoint = `enrolment-import/enrolment/${loId}/credit?x-expensive=1`;
  return axios.post(endpoint, { instance: instanceName, users });
}

function purchaseManually({ loId, creditCount, description }) {
  const endpoint = `enrolment/manual-payment/bulk/${loId}/${creditCount}/0`;
  return axios.post(endpoint, { description });
}

function requestPurchase({ instanceId, loId, managerId }) {
  const data = {
    loId,
    instance: instanceId,
    managerUserId: managerId,
  };
  return axios.post('payment-purchase-request', data);
}

export {
  purchaseManually,
  requestPurchase,
  bulkAssignCredits,
  purchaseCredits,
};
