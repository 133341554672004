import * as actions from './actions';

const INITIAL_STATE = {
  method: 'creditCard',
  isSucceeded: false,
  isProcessing: false,
  error: false,
};

function paymentFormReducers(state = { ...INITIAL_STATE }, action) {
  switch (action.type) {
    case actions.CHANGE_METHOD:
      return { ...state, method: action.payload };

    case actions.PURCHASE_LEARNING_SUCCEEDED:
      return { ...state, isSucceeded: true, isProcessing: false };

    case actions.PURCHASE_LEARNING_PROCESSING:
      return { ...state, isSucceeded: false, isProcessing: true };

    case actions.REQUEST_LEARNING_DONE:
      return {
        ...state,
        isSucceeded: false,
        isProcessing: false,
        error: false,
      };

    case actions.PURCHASE_LEARNING_ERROR:
      return {
        ...state,
        isSucceeded: false,
        isProcessing: false,
        error: true,
      };

    default:
      return state;
  }
}

export default paymentFormReducers;
