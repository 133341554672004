import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedDate, FormattedMessage } from 'react-intl';

export const DateRange = ({
  start,
  end,
  intl,
}) => {
  const sameDate = start.toDateString() === end.toDateString();
  const sameYear = start.getFullYear() === end.getFullYear();
  const eventInYear = sameYear && (new Date()).getFullYear() === start.getFullYear();
  const showYear = !eventInYear;

  const commonProps = {
    minute: "numeric",
    hour: "numeric",
    day: "numeric",
    weekday: "long",
    month: "long",
    locale: intl.locale,
  };

  if (showYear) {
    commonProps.year = "numeric";
  }

  const startDate = {
    ...commonProps,
    value: start,
  };

  const endDate = {
    ...commonProps,
    value: end,
  };

  if (sameDate) {
    return (
      <React.Fragment>
        <FormattedDate {...startDate} /> &ndash; <FormattedDate value={end} hour="numeric" minute="numeric" />
      </React.Fragment>
    );
  }

  return (
    <FormattedMessage
      id="app.content.dateRange"
      defaultMessage="{startDate} to {endDate}"
      values={{
        startDate: <FormattedDate {...startDate} />,
        endDate: <><br /><FormattedDate {...endDate} /></>,
      }}
    />
  );
};

DateRange.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(DateRange);
