import { action } from '../../../utils';


export const SET_PRICE = 'PAYMENT/DETAIL/SET_PRICE';
export const ADD_SEAT = 'PAYMENT/DETAIL/ADD_SEAT';
export const REMOVE_SEAT = 'PAYMENT/DETAIL/REMOVE_SEAT';
export const ADD_COUPON = 'PAYMENT/DETAIL/ADD_COUPON';
export const REMOVE_COUPON = 'PAYMENT/DETAIL/REMOVE_COUPON';
export const ADD_SELECTED_USER = 'PAYMENT/DETAIL/ADD_SELECTED_USER';
export const REMOVE_SELECTED_USER = 'PAYMENT/DETAIL/REMOVE_SELECTED_USER';
export const UPDATE_SELECTED_USERS = 'PAYMENT/DETAIL/UPDATE_SELECTED_USERS';

export const setPrice = action(SET_PRICE);
export const addSeat = action(ADD_SEAT);
export const removeSeat = action(REMOVE_SEAT);
export const addCoupon = action(ADD_COUPON);
export const removeCoupon = action(REMOVE_COUPON);
export const addSelectedUser = action(ADD_SELECTED_USER);
export const removeSelectedUser = action(REMOVE_SELECTED_USER);
export const updateSelectedUsers = action(UPDATE_SELECTED_USERS);
