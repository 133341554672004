import axios from "axios";

/* istanbul ignore next */
export function signInUser(payload) {
  const { username, password, portal } = payload;
  return axios
    .post(`${process.env.BASE_URL}/user/account/login`, {
      username,
      password,
      portal
    })
    .then(async response => {
      const user = response.data;
      const authenticated = true;
      const userInPortal = user.accounts.some(
        Account => Number(Account.instance.id) === portal
      );
      if (userInPortal === false) {
        // this fallback should be redundant with the addition of a portal payload above
        const Portal = await axios
          .get(`${process.env.BASE_URL}/portal/${portal}`)
          .then(x => x.data);

        return axios
          .post(`${process.env.BASE_URL}/user/account`, {
            email: user.mail,
            instance: process.env.INSTANCE_URL,
            portal: Portal.title
          })
          .then(AccountResponse => ({
            user: AccountResponse.data,
            authenticated
          }));
      }
      return { user, authenticated };
    });
}

/* istanbul ignore next */
export function getUserLoc() {
  return axios
    .get("https://extreme-ip-lookup.com/json/")
    .then(response => response.data);
}

/* istanbul ignore next */

export function registerUser({
  instance,
  portal,
  email,
  password,
  random,
  first_name,
  last_name,
  data
}) {
  return axios.post(`${process.env.BASE_URL}/user/account`, {
    instance,
    portal,
    email,
    password,
    random,
    first_name,
    last_name,
    data
  });
}


export async function requestUserManagers(portalName, accountId) {
  const response = await axios.get(`user/user/${portalName}/${accountId}/managers`);
  return response.data;
}
