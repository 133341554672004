import axios from 'axios';

/* istanbul ignore next */
async function setPublishableKey(instanceName) {
  const response = await axios.get(`portal/stripe/${instanceName}/public-key`);
  window.Stripe.setPublishableKey(response.data.public_key);
}

/* istanbul ignore next */
async function createToken(cardInfo) {
  if (!cardInfo) {
    return Promise.resolve('any');
  }

  return new Promise((resolve, reject) => {
    window.Stripe.card.createToken(cardInfo, (status, data) => {
      if (data.id) {
        resolve(data.id);
      } else {
        reject(data.error ? data.error.message : null);
      }
    });
  });
}

export {
  setPublishableKey,
  createToken,
};
