import { validate } from 'validate.js';
import moment from 'moment';
import { injectGetMessage } from '../../utils';

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse(value) {
    return +moment.utc(value, 'YYYY/MM');
  },
  // Input is a unix timestamp
  format(value, options) {
    const format = options.dateOnly ? 'YYYY/MM' : 'YYYY/MM hh:mm:ss';
    return moment.utc(value, format);
  },
});

export const constraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.name_required' }),
        'name_required',
      ),
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.email_required' }),
        'email_required',
      ),
    },
    email: {
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.email_valid_required' }),
        'email_valid_required',
      ),
    },
  },
  phone_number: {
    format: {
      pattern: '^([(]\\d{2}[)])([ ]\\d{4})([-]\\d{4})$',
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.phone_invalid' }),
        'phone_invalid',
      ),
    },
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.phone_required' }),
        'phone_required',
      ),
    },
  },
  document: {
    format: {
      pattern: '^(\\d{3})([ ](\\d{3})){2}[ ](\\d{2})$',
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.document_invalid' }),
        'document_invalid',
      ),
    },
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.document_required' }),
        'document_required',
      ),
    },
  },
  street_number: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.street_no_required' }),
        'street_no_required',
      ),
    },
  },
  address: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.street_name_required' }),
        'street_name_required',
      ),
    },
  },
  city: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.city_required' }),
        'city_required',
      ),
    },
  },
  state: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.state_required' }),
        'state_required',
      ),
    },
  },
  zipcode: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.zipcode_required' }),
        'zipcode_required',
      ),
    },
    format: {
      pattern: '^\\d{5}(-\\d{3})$',
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.zipcode_invalid' }),
        'zipcode_invalid',
      ),
    },
  },
  country: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.country_required' }),
        'country_required',
      ),
    },
  },
  ccName: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.cc_name_required' }),
        'cc_name_required',
      ),
    },
  },
  ccNumber: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.cc_number_required' }),
        'cc_number_required',
      ),
    },
    format: {
      pattern: '^[0-9]*$',
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.cc_number_numeric' }),
        'cc_number_numeric',
      ),
    },
    length: {
      maximum: 19,
    },
  },
  ccExpiry: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.cc_expiry_required' }),
        'cc_expiry_required',
      ),
    },
    datetime: {
      dateOnly: true,
      earliest: moment(moment.utc().subtract(1, 'M'), 'YYYY/MM'),
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.cc_expiry_invalid' }),
        'cc_expiry_invalid',
      ),
    },
  },
  ccCVV: {
    presence: {
      allowEmpty: false,
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.cc_cvv_required' }),
        'cc_cvv_required',
      ),
    },
    format: {
      pattern: '^[0-9]*$',
      message: injectGetMessage(
        getMessage => getMessage({ id: 'error.cc_cvv_numeric' }),
        'cc_cvv_numeric',
      ),
    },
  },
};

export function validation(body, cont) {
  return validate(body, cont || constraints);
}
