
import { action } from '../../utils';

export const LOGIN = 'AUTH/LOGIN';
export const LOGIN_SUCCEEDED = 'AUTH/LOGIN/SUCCEEDED';
export const LOGIN_FAILED = 'AUTH/LOGIN/FAILED';

export const login = action(LOGIN);
export const loginSucceeded = action(LOGIN_SUCCEEDED);
export const loginFailed = action(LOGIN_FAILED, true);

export const LOGOUT = 'AUTH/LOGOUT';

export const logout = action(LOGOUT);

export const UPDATE_AUTHOR_PERMISSION = 'AUTH/PERMISSIONS/UPDATE/AUTHOR';

export const updateAuthorPermission = action(UPDATE_AUTHOR_PERMISSION);

export const UPDATE_PORTAL_INFO = 'AUTH/PORTAL/INFO';

export const updatePortalInfo = action(UPDATE_PORTAL_INFO);

export const GET_PORTAL_KEY = 'AUTH/PORTAL/JWT';

export const swapApiKeyForJwt = action(GET_PORTAL_KEY);
