import axios from 'axios';

axios.defaults.baseURL = `${process.env.BASE_URL}`;

export function setCredentials(token) {
  if (token) {
    axios.defaults.headers = {
      Authorization: `Bearer ${token}`,
    };
  } else {
    delete axios.defaults.headers;
  }
}
