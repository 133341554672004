import { action } from '../../utils';

export const CHANGE_METHOD = 'PAYMENT_FORM/CHANGE_METHOD';
export const PURCHASE_LEARNING_PROCESSING = 'PAYMENT_FORM/PURCHASE_LEARNING/PROCESSING';
export const PURCHASE_LEARNING_ERROR = 'PAYMENT_FORM/PURCHASE_LEARNING/ERROR';
export const REQUEST_LEARNING_DONE = 'PAYMENT_FORM/REQUEST_LEARNING/DONE';
export const PURCHASE_LEARNING_SUCCEEDED = 'PAYMENT_FORM/PURCHASE_LEARNING/SUCCEEDED';

export const changeMethod = action(CHANGE_METHOD);
export const purchaseLearningProcessing = action(PURCHASE_LEARNING_PROCESSING);
export const purchaseLearningError = action(PURCHASE_LEARNING_ERROR);
export const requestLearningDone = action(REQUEST_LEARNING_DONE);
export const purchaseLearningSucceeded = action(PURCHASE_LEARNING_SUCCEEDED);
