import userReducer from './components/login/reducers';
import contentReducer, * as content from './components/content/reducers';
import paymentReducer from './components/payment/reducers';
import paymentDetailReducer from './components/payment/detail/reducers';
import paymentFormReducer from './components/PaymentForm/reducers';

const rootReducers = {
  auth: userReducer,
  content: contentReducer,
  payment: paymentReducer,
  paymentDetail: paymentDetailReducer,
  paymentForm: paymentFormReducer,
};

export default rootReducers;

export const getLO = state => content.getLO(state.apps);
export const getMaxEnrollCount = state => state.content.maxEnrollCount;
export const getUser = state => state.auth && state.auth.user;
export const getAccount = state => state.auth && state.auth.account;
export const getAuthenticated = state => state.auth && state.auth.authenticated;
export const getPayment = state => state.payment;
export const getPaymentDetail = state => state.paymentDetail;
export const getPaymentMethod = state => state.paymentForm.method;
export const getPortalId = state => state.content.lo.portalId;
export const getPortal = state => state.auth.portal;
export const getRegister = state => state.register;
export const isEnrolled = state => state.auth && !!state.auth.enrolled;
export const isPaymentSucceeded = state => state.paymentForm && state.paymentForm.isSucceeded;
export const isPaymentProcessing = state => state.paymentForm && state.paymentForm.isProcessing;
export const hasPermissionOnCourse = (state) => {
  const { auth } = state;
  return auth.permissions.isAdmin || auth.permissions.isManager || auth.permissions.isAuthor;
};
