import * as actions from './actions';
import unionBy from 'lodash/unionBy';
import castArray from 'lodash/castArray';
import differenceBy from 'lodash/differenceBy';

const INITIAL_STATE = {
  seatCount: 1,
  totalAmount: null,
  basePrice: null,
  currency: 'AUD',
  coupon: null,
  selectedUsers: [],
  discountAmount: null,
  tax: null,
  taxAmount: null,
  subTotal: null,
};

function computePaymentPrice(basePrice, seatCount, coupon, tax) {
  const taxRatio = tax ? (tax / 100) : 0;
  const subTotal = basePrice * seatCount;

  if (!coupon) {
    const taxAmount = subTotal * taxRatio;
    return {
      price: Math.max(Number((subTotal + taxAmount).toFixed(2)), 0),
      subTotal: Number(subTotal.toFixed(2)),
      taxAmount: Number(taxAmount.toFixed(2)),
      discountAmount: 0,
    };
  }

  const value = Number(coupon.value) || 0;
  const isAmountCoupon = coupon.type === 'amount';
  const discountAmount = isAmountCoupon ? value : ((subTotal * value) / 100);
  const price = subTotal - discountAmount;
  const taxAmount = price * taxRatio;
  const finalPrice = price + taxAmount;
  return {
    price: Math.max(Number(finalPrice.toFixed(2)), 0),
    subTotal,
    taxAmount: Number(taxAmount.toFixed(2)),
    discountAmount: Number(discountAmount.toFixed(2)),
  };
}

function reduceStateWithPrice(state) {
  const {
    basePrice,
    seatCount,
    coupon,
    tax,
  } = state;
  const paymentPrice = computePaymentPrice(basePrice, seatCount, coupon, tax);
  return {
    ...state,
    totalAmount: paymentPrice.price,
    subTotal: paymentPrice.subTotal,
    taxAmount: paymentPrice.taxAmount,
    discountAmount: paymentPrice.discountAmount,
  };
}

export function paymentDetailReducer(state = { ...INITIAL_STATE }, action) {
  switch (action.type) {
    case actions.SET_PRICE:
      return reduceStateWithPrice({
        ...state,
        basePrice: action.payload.price,
        currency: action.payload.currency,
        tax: action.payload.tax,
      });

    case actions.ADD_SEAT:
      return reduceStateWithPrice({
        ...state,
        seatCount: state.seatCount + 1,
      });

    case actions.REMOVE_SEAT:
      return reduceStateWithPrice({
        ...state,
        seatCount: state.seatCount - 1,
      });

    case actions.ADD_COUPON:
      return reduceStateWithPrice({
        ...state,
        coupon: action.payload,
      });

    case actions.REMOVE_COUPON:
      return reduceStateWithPrice({
        ...state,
        coupon: null,
      });

    case actions.UPDATE_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: action.payload ? action.payload : [],
      };

    case actions.ADD_SELECTED_USER:
      return {
        ...state,
        selectedUsers: unionBy(state.selectedUsers, castArray(action.payload || []), 'mail'),
      };

    case actions.REMOVE_SELECTED_USER:
      return {
        ...state,
        selectedUsers: differenceBy(state.selectedUsers, castArray(action.payload || []), 'mail'),
      };

    default:
      return state;
  }
}

export default paymentDetailReducer;

export const getPaymentDetail = state => state.paymentDetail;
