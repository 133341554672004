import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getAuthenticated, getUser } from '../../reducers';
import { getLO } from '../content/reducers';
import InlineAlert from '../../components/InlineAlert';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    user: getUser(state),
    isAuthenticated: getAuthenticated(state),
    lo: getLO(state),
  };
}

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({ dispatch });

export class Enrolled extends React.PureComponent {
  static propTypes = {
    lo: PropTypes.objectOf(PropTypes.any).isRequired,
    redirectUrl: PropTypes.string.isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    existing: PropTypes.boolean,
  }

  render() {
    const { lo, user, redirectUrl, existing } = this.props;

    return (
      <InlineAlert classNames="w-100 m-3" >
        <span className="description">
          {existing && <>
            <FormattedMessage
              id="app.alreadyEnrolled"
              defaultMessage="You are already enrolled in"
            /> {lo.title}.
          </> || <>
            <FormattedMessage
              id="app.successfullyEnrolled"
              defaultMessage="You have successfully enrolled in"
            /> {lo.title}.
          </>}
        </span>
        {redirectUrl &&
          <p className="mb-0">
            <a href={redirectUrl} target="_top">
              <FormattedMessage
                id="app.continueLink"
                defaultMessage="Please click here to continue."
              />
            </a>
          </p>}
      </InlineAlert>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Enrolled);
