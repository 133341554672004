import axios from 'axios';
import find from 'lodash/find';
import { courseHelper } from '../helpers/courseHelper';
import { createFromResponse } from '../helpers/couponHelper';
import { eventSessionHelper } from '../helpers/eventSessionHelper';

/* istanbul ignore next */
export function requestLO(loId, shareId, instanceId, jwt) {
  const params = {
    instance: true,
    author: 1,
    event: -1
  };

  if (shareId) {
    params.policyId = shareId;
  }

  return axios.get(`lo-service/lo/single/${loId}/${instanceId}`, {
    headers: {
      "Authorization": `Bearer ${jwt}`
    },
    params
  })
    .then(response => response.data && courseHelper.createFromResponse(response.data, { policyId: shareId }));
}

/* istanbul ignore next */
export function requestEventSession(eventId, moduleId, jwt) {
  const url = `event/learning-objects/${eventId}/events`;
  const apiJWT = `Bearer ${jwt}`;
  const headers = jwt ? { Authorization: apiJWT } : {};
  return axios.get(url, { headers })
    .then(response => {
      const { data } = response;
      const list = data.sessions.map(blob => {
        const formattedEvent = eventSessionHelper.createFromResponse(blob);
        return {
          ...formattedEvent,
          eventId,
          moduleId,
        };
      });
      return list;
    });
}

/* istanbul ignore next */
export function requestEventSessions(lo, jwt) {
  if (lo.events && !lo.events.length) {
    return Promise.resolve([]);
  }
  const promises = lo.events.map(event => requestEventSession(event.id, event.moduleId, jwt));
  return Promise.all(promises).then(sessions => [].concat(...sessions));
}

/* istanbul ignore next */
export function requestPortal(id) {
  return axios.get(`/portal/${id}`).then(response => response.data);
}

/* istanbul ignore next */
export function getJwtForApiKey(apiKey) {
  return axios.get(`/user/current/${apiKey}`).then(response => response.data);
}

/* istanbul ignore next */
export function requestEnrolStatus(loid, portalId, jwt) {
  return axios.get(`/enrolment/lo/${loid}?portalId=${portalId}&jwt=${jwt}`)
    .then(response => response.data);
}

/* istanbul ignore next */
export function requestEnrolLO(loid, portal, jwt) {
  return axios.post(`/enrolment/${portal}/${loid}/enrolment`)
    .then(response => response.data);
}

/* istanbul ignore next */
export function requestPaymentType(portalName) {
  return axios.get(`portal/conf/${portalName}/payment/payment_type`)
    .then(response => response.data);
}

/* istanbul ignore next */
export function requestCouponByCode(instanceId, code) {
  const endpoint = `coupon/coupon/${instanceId}/${code}`;
  return axios.get(endpoint)
    .then(response => createFromResponse(response.data))
    .catch(error => error);
}

export function requestExchangeRate(from, to) {
  const url = `currency/${from}/${to}`;
  return axios.get(url)
    .then(response => response.data);
}

/* istanbul ignore next */
export function requestEnrolLOPaid(loid, portal, jwt, {
  token,
  email,
  full_name,
  payment_type,
  document,
  zip_code,
  address,
  street_number,
  city,
  state,
  country,
  phone_number,
}) {
  return axios.post(`/enrolment/${portal}/${loid}/enrolment`, {
    paymentMethod: 'ebanx',
    paymentOptions: {
      token,
      email,
      full_name,
      payment_type,
      document,
      zip_code,
      address,
      street_number,
      city,
      state,
      country,
      phone_number,
    },
  })
    .then(response => response.data);
}

/* istanbul ignore next */
export function fetchUsers(portalName, query) {
  return axios.get(`user-service/account/chipscontact/${portalName}/${query}`)
    .then(response => response.data);
}

/* istanbul ignore next */
export function fetchUser(portalName, mail) {
  return axios.get(`user-service/account/chipscontact/${portalName}/${mail}`)
    .then(response => find(response.data, (user) => user.mail === mail));
}

/* istanbul ignore next */
export function fetchUnenrolledUsersToCourse(portalName, loId, query) {
  return axios.request({
    method: 'post',
    url: `account-report/users-enrolled/${portalName}/${loId}/${loId}?x-expensive=1&use-lr-index `,
    data: {
      query: {
        bool: {
          must: [],
          filter: {
            multi_match: {
              fields: [
                'name',
                'mail',
                'roles',
                'status',
                'account_enrolment.status',
                'name.analyzed',
                'mail.analyze',
              ],
              query,
              type: 'phrase',
              lenient: true
            }
          }
        }
      },
      sort: [],
      from: 0,
      size: 200
    },
  }).then(response => response.data.hits.hits);
}
