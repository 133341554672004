import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Button } from 'reactstrap';
import { getUser, getAuthenticated, getPortalId } from '../../reducers';
import { getLO } from '../content/reducers';
import InlineAlert from '../InlineAlert';
import { requestEnrolLO } from '../../api';
import * as actions from '../payment/actions';
import { FormattedMessage } from 'react-intl';


/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    user: getUser(state),
    isAuthenticated: getAuthenticated(state),
    lo: getLO(state),
    portalId: getPortalId(state),
  };
}

export class FreeForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    lo: PropTypes.objectOf(PropTypes.any).isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    color: PropTypes.string,
    portalId: PropTypes.number.isRequired,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    color: '#31b8da',
    loading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      response: null,
      responseText: '',
    };
    this.submitHandler = this.submitHandler.bind(this);
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { jwt } = this.props.user;
    const { id } = this.props.lo;
    const { portalId } = this.props;

    requestEnrolLO(id, portalId, jwt)
      .then((resp) => {
        this.props.dispatch(actions.enrolSucceeded(this.props.user, this.props.lo, resp));
        this.setState({
          response: 'success',
          responseText: 'Congratulations! You are now enrolled in this course.',
        });
      })
      .catch((err, res) => {
        this.props.dispatch(actions.enrolFailed(err));
        this.setState({
          response: 'danger',
          responseText: `Unable to enrol: ${err.response.data.message}`,
        });
      });
  }

  render() {
    const bgColor = {
      background: this.props.color,
    };
    const { loading } = this.props;
    return (
      <div className="free-enrollment">
        <Form onSubmit={this.submitHandler}>
          <h3><FormattedMessage id="app.freeEnrollment" defaultMessage="Free Enrollment" />!</h3>
          <FormGroup>
            {this.state.response && this.state.responseText &&
              <InlineAlert text={this.state.responseText} variety={this.state.response} />
            }
            {!this.state.response &&
              <Button color="primary" className="btn-lg" style={bgColor} disabled={loading}>
                <FormattedMessage id="app.confirm" defaultMessage="Confirm" />
              </Button>
            }
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FreeForm);
