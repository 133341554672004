import some from 'lodash/some';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getLO, getAvailableEventSessions } from './reducers';
import { getAuthenticated, getUser, getPaymentDetail, hasPermissionOnCourse } from '../../reducers';

import { CoursePreview } from './CoursePreview/CoursePreview';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    user: getUser(state),
    isAuthenticated: getAuthenticated(state),
    lo: getLO(state),
    eventSessions: getAvailableEventSessions(state),
    paymentDetail: getPaymentDetail(state),
    hasPermission: hasPermissionOnCourse(state),
  };
}

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({ dispatch });

export const ContentPreview = ({
  lo,
  user,
  contentType,
  eventSessions,
  paymentDetail,
  portalId,
  paymentSucceeded,
  redirectUrl,
  exploreUrl,
  hasPermission,
  userUrl,
  location,
}) => {
  let isSelfPurchased;
  const headerMessage = (
    <div className="message">
      <FormattedMessage
        id="app.payment.successful"
        defaultMessage="Payment successful!"
        tagName="h3"
      />

      <FormattedMessage
        id="app.payment.thanks"
        defaultMessage="Thank you for your purchase."
        tagName="div"
      />
    </div>
  );
  if (contentType === 'event') {
    isSelfPurchased = some(paymentDetail.selectedUsers, ['mail', user.mail]);
  }
  return (
    <CoursePreview
      lo={lo}
      user={user}
      eventSessions={eventSessions}
      paymentDetail={paymentDetail}
      portalId={portalId}
      viewOnly={paymentSucceeded}
      redirectUrl={redirectUrl}
      exploreUrl={exploreUrl}
      userUrl={userUrl}
      hasPermission={hasPermission}
      isSelfPurchased={isSelfPurchased}
      headerMessage={paymentSucceeded ? headerMessage : null}
      location={location}
    />
  );
};

ContentPreview.propTypes = {
  lo: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  contentType: PropTypes.string,
  eventSessions: PropTypes.arrayOf(PropTypes.any).isRequired,
  paymentDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  portalId: PropTypes.number.isRequired,
  paymentSucceeded: PropTypes.bool,
  redirectUrl: PropTypes.string,
  exploreUrl: PropTypes.string,
  userUrl: PropTypes.string,
  hasPermission: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.any),
};

ContentPreview.defaultProps = {
  contentType: 'default',
  paymentSucceeded: true,
  redirectUrl: null,
  exploreUrl: null,
  userUrl: null,
  hasPermission: false,
  location: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentPreview);
