import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { FormattedMessage } from 'react-intl';

import { Row, Col } from 'reactstrap';
import { Avatar, ButtonMinimal, Icon } from '@go1d/go1d';
import { SearchUser } from '../common/SearchUser';

import { getPaymentDetail, getPortal, hasPermissionOnCourse } from '../../../reducers';
import { getLO } from '../../content/reducers';
import * as paymentDetailActions from '../../payment/detail/actions';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import './CourseAssign.scss';

/* istanbul ignore next */
const mapStateToProps = state => ({
  paymentDetail: getPaymentDetail(state),
  lo: getLO(state),
  portal: getPortal(state),
  hasPermission: hasPermissionOnCourse(state),
});

export class CourseAssign extends React.PureComponent {
  selectUser = users => {
    this.props.dispatch(paymentDetailActions.addSelectedUser(users));
  }

  removeUser = users => {
    this.props.dispatch(paymentDetailActions.removeSelectedUser(users));
  }

  renderUser = (user, viewOnly) => (
    <Row key={user.mail}>
      <Col xs="2" md="1" lg="1" xl="2">
        <Avatar src={user.image} fullName={user.name} size={5} />
      </Col>
      <Col xs="9" sm="10" className="course-assign--user">
        <div className="course-assign--user-name">{user.name}</div>
        <div key="mail" className="course-assign--user-mail">{user.mail}</div>
      </Col>
      {!viewOnly && this.props.hasPermission &&
        <div className="course-assign--remove">
          <ButtonMinimal backgroundColor="background" onClick={() => this.removeUser(user)}>
            <Icon name="Cross" />
          </ButtonMinimal>
        </div>
      }
    </Row>
  );

  render() {
    const {
      portal,
      lo,
      defaultUser,
      paymentDetail
    } = this.props;
    const { selectedUsers, seatCount } = paymentDetail;
    const { viewOnly } = this.props;
    const remainingSeat = seatCount - (selectedUsers.length || 0);
    return (
      <div className="course-assign">
        {!viewOnly && !isEmpty(defaultUser) && selectedUsers.length > 0 &&
          <div className="user-info">
            <h3><FormattedMessage id="app.purchasedFor" defaultMessage="Purchased for" /></h3>
          </div>
        }
        <div className="course-assign--selected-users">
          {selectedUsers &&
            selectedUsers.map(user => this.renderUser(user, viewOnly))
          }
        </div>
        {!viewOnly && !isEmpty(defaultUser) && remainingSeat > 0 &&
          <SearchUser
            remainingSeat={remainingSeat}
            renderUser={this.renderUser}
            selectUser={this.selectUser}
            portalName={portal.title}
            loId={lo.id}
          />
        }
      </div>
    );
  }
}

CourseAssign.propTypes = {
  paymentDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  portal: PropTypes.objectOf(PropTypes.any).isRequired,
  lo: PropTypes.objectOf(PropTypes.any).isRequired,
  defaultUser: PropTypes.objectOf(PropTypes.any).isRequired,
  hasPermission: PropTypes.bool,
};

CourseAssign.defaultProps = {
  viewOnly: true,
  hasPermission: false,
};

export default connect(mapStateToProps)(CourseAssign);
